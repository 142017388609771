import { useEffect } from 'react';

const useBodyClass = (className) => {
  useEffect(() => {
    // Add the class to the body element
    document.body.classList.add(className);

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);
};

export default useBodyClass;
