import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faCheckCircle, faTimesCircle, faHourglassHalf, faEye, faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AdminCards from './Dashboard';
import { useSession } from './../Common/SessionContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Fuse from 'fuse.js';

const List = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const { sessionData } = useSession();
  const serverUrl = process.env.REACT_APP_API_URL;
  const fuseOptions = {
    keys: ['product_name', 'req_id', 'branch_code'], // Specify keys to search within
    threshold: 0.4, // Adjust this value to increase/decrease the fuzzy matching tolerance
    minMatchCharLength: 2, // Minimum number of characters needed to trigger search
  };
  const fuse = new Fuse(requests, fuseOptions);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/product-list`);
        setRequests(response.data);
        setFilteredRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product requests:', error);
        swal({
          title: "PLEASE LOGIN AGAIN",
          text: "Failed to fetch product requests. Please try again later.",
          icon: "error",
          button: "OK",
        });
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  useEffect(() => {
    // Function to filter requests based on the selected filter
    const filterRequests = () => {
      if (filter === '') {
        setFilteredRequests(requests);
      } else if (filter === 'total') {
        setFilteredRequests(requests);
      } else if (filter === 'pending') {
        const filtered = requests.filter(request => request.status.toLowerCase() === 'pending' || request.status.toLowerCase() === 'verified');
        setFilteredRequests(filtered);
      } else if (filter === 'approved') {
        const filtered = requests.filter(request => request.status.toLowerCase() === 'approved');
        setFilteredRequests(filtered);
      } else if (filter === 'rejected') {
        const filtered = requests.filter(request => request.status.toLowerCase() === 'rejected');
        setFilteredRequests(filtered);
      } else if (filter === 'pending_verified') {
        const filtered = requests.filter(request => request.status.toLowerCase() === 'pending' || request.status.toLowerCase() === 'verified');
        setFilteredRequests(filtered);
      }
    };

    filterRequests();
  }, [filter, requests]);
  // useEffect(() => {
  //   const filtered = requests.filter((request) =>
  //     request.product_name.toLowerCase().includes(search.toLowerCase()) ||
  //     request.req_id.toLowerCase().includes(search.toLowerCase()) ||
  //     request.branch_code.toLowerCase().includes(search.toLowerCase())
  //     // request.item_code.toLowerCase().includes(search.toLowerCase())
  //   );
  //   setFilteredRequests(filtered);
  // }, [search, requests]);
  useEffect(() => {
    if (search.trim() === '') {
      setFilteredRequests(requests); // If no search term, show all requests
    } else {
      // Perform search
      const results = fuse.search(search).map(({ item }) => item);
      setFilteredRequests(results);
    }
  }, [search, requests]);

  const handleViewClick = async (row) => {
    try {
      const response = await axios.get(`${serverUrl}/api/category-req-data/${row.req_id}`);
      navigate(`/${sessionData.userRole}/product/${row.req_id}`, { state: { request: response.data } });
    } catch (error) {
      console.error('Error sending request data:', error);
      swal({
        title: "PLEASE LOGIN AGAIN",
        text: "Failed to send request data. Please try again later.",
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleCardClick = (filterValue) => {
    setFilter(filterValue);
  };

  const getBadgeClassAndContent = (mainCategory) => {
    let badgeClass = '';
    let content = '';

    switch (mainCategory) {
      case '1':
        badgeClass = 'badge-pending';
        content = 'SURGICAL';
        break;
      case '2':
        badgeClass = 'badge-approved';
        content = 'MEDICINE';
        break;
      case '3':
        badgeClass = 'badge-rejected';
        content = 'FMCG FOOD';
        break;
      case '4':
        badgeClass = 'badge-verified';
        content = 'FMCG NON FOOD';
        break;
      case '5':
        badgeClass = 'badge-verified';
        content = 'OPRS';
        break;
      default:
        badgeClass = 'badge-default';
        content = 'N/A';
        break;
    }

    return { badgeClass, content };
  };

  const columns = [
    { name: 'REQUEST ID', selector: row => row.req_id || 'N/A', sortable: true, width: '130px', fixed: 'left' },
    { name: 'BRANCH CODE', selector: row => row.branch_code || 'N/A', sortable: true, width: '140px', fixed: 'left' },
    {
      name: 'PRODUCT NAME',
      cell: row => (
        <>
          {row.product_name || 'N/A'}
          {row.product_information?.rejectionReason && ( // Optional chaining to avoid errors
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">{row.product_information.rejectionReason}</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ marginLeft: '5px', color: 'red', cursor: 'pointer' }}
              />
            </OverlayTrigger>
          )}
        </>
      ),
      sortable: true,
      width: '200px'
    },
    { name: 'CREATED BY', selector: row => row.created_by || 'N/A', sortable: true, width: '130px' },
    {
      name: 'CATEGORY', selector: row => row.main_category || 'N/A', sortable: true,
      cell: row => {
        const { badgeClass, content } = getBadgeClassAndContent(row.main_category);
        return (
          <span className={`badge ${badgeClass}`}>
            {content}
          </span>
        );
      },
      width: '120px'
    },
    { name: 'CREATED AT', selector: row => moment(row.created_at).format('DD/MM/YYYY, h:mm:ss a') || 'N/A', sortable: true, width: '180px' },
    // { name: 'UPDATED AT', selector: row => moment(row.updated_at).format('DD/MM/YYYY, h:mm:ss a') || 'N/A', sortable: true, width: '190px' },
    {
      name: 'TAT',
      selector: row => {
        const createdAt = moment(row.created_at);
        const updatedAt = moment(row.updated_at);
        const duration = moment.duration(updatedAt.diff(createdAt));

        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();

        return `${days}d : ${hours}h : ${minutes}m`;
      },
      sortable: true,
      width: '130px'
    },
    // { name: 'REJECTION REASON', selector: row => row.product_information.rejectionReason || '', sortable: true, width: '250px' },
    {
      name: 'STATUS',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <span className={`badge ${row.status === 'Pending' ? 'badge-pending' :
          row.status === 'Approved' ? 'badge-approved' :
            row.status === 'Rejected' ? 'badge-rejected' :
              'badge-verified'
          }`}>
          {row.status === 'Pending' && <FontAwesomeIcon icon={faHourglassHalf} style={{ marginRight: '5px' }} />}
          {row.status === 'Approved' && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '5px' }} />}
          {row.status === 'Rejected' && <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '5px' }} />}
          {row.status === 'Verified' && <FontAwesomeIcon icon={faCheck} style={{ marginRight: '5px' }} />}
          {row.status}
        </span>
      ),
      width: '150px'
    },
    { name: 'ITEM CODE', selector: row => row.item_code || '', sortable: true, width: '150px' },
    {
      name: 'VIEW',
      cell: row => (
        <button className="btn btn-link">
          <FontAwesomeIcon icon={faEye} onClick={() => handleViewClick(row)} />
        </button>
      ),
      width: '100px'
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };

  return (
    <div className="container-fluid">
      <AdminCards onCardClick={handleCardClick} />
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card shadow bg-body rounded">
            <div className="card-body">

              <div className="row mb-3">
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="Search by Req Id, Branch Code, Product Name"
                    className="form-control"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: '40%' }}
                  />
                </div>
              </div>
              <DataTable
                columns={columns}
                data={filteredRequests}
                progressPending={loading}
                pagination
                responsive
                striped
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
