import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faMapLocation } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

const CustomNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear session logic (assuming you are using sessionStorage or localStorage)
    localStorage.removeItem('token'); // Example: Clear token from localStorage
    localStorage.removeItem('branchCode'); // Clear any other stored data
    localStorage.removeItem('branchName');
    localStorage.removeItem('roleName');

    navigate('/');

    // Uncomment when using swal for logout confirmation
    // swal({
    //   title: "Logged Out!",
    //   text: "You have been successfully logged out.",
    //   icon: "success",
    //   button: "OK",
    // }).then(() => {
    //   // Handle any post-logout actions if needed
    // });
  };

  const branchCode = localStorage.getItem('branchCode');
  const branchName = localStorage.getItem('branchName');
  const userName = localStorage.getItem('userName');

  return (
    <Navbar style={{ backgroundColor: '#004400' }}>
      <Container>
        <Navbar.Brand as={Link} to="/user" className="d-flex align-items-center text-white">
          <FontAwesomeIcon icon={faMapLocation} className="mr-1" />
          {branchCode && <span className="ml-2">{branchCode}</span>}
          {branchName && <span className="ml-2">{branchName}</span>}
        </Navbar.Brand>

        <Nav className="ml-auto">
          <Nav.Link className="text-white">
            <FontAwesomeIcon icon={faUserCircle} className="mr-1" />
            {userName}
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
