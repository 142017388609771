import React from 'react';
import { useNavigate,Link } from 'react-router-dom';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const BottomNav = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear session logic (assuming you are using sessionStorage or localStorage)
        localStorage.removeItem('token'); // Example: Clear token from localStorage
        localStorage.removeItem('branchCode'); // Clear any other stored data
        localStorage.removeItem('branchName');
        localStorage.removeItem('roleName');

        navigate('/');

        // swal({
        //   title: "Logged Out!",
        //   text: "You have been successfully logged out.",
        //   icon: "success",
        //   button: "OK",
        // }).then(() => {

        // });
    };

    return (
        <footer className="footer-nav">
            <Link className="nav-link" to="/user">
            {/* Dashboard */}
            <div className="nav-item">
                <div className="nav-icon"><FontAwesomeIcon icon={faHome} /></div>
                <div className="nav-text">Home</div>
            </div>
            </Link>
            <Link className="nav-link" to="/user/request-list">
            <div className="nav-item">
                <div className="nav-icon"><FontAwesomeIcon icon={faList} /></div>
                <div className="nav-text">Request List</div>
            </div>
            </Link>
            <Link className="nav-link" to="/logout">
            <div className="nav-item">
                <div className="nav-icon"><FontAwesomeIcon icon={faSignOutAlt} /></div>
                <div className="nav-text">Logout</div>
            </div>
            </Link>
            
        </footer>
    );
};

export default BottomNav;
