
import React from 'react';

const ForbiddenPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>500 - Forbidden</h1>
      <p>You do not have permission to access this page.</p>
    </div>
  );
};

export default ForbiddenPage;
