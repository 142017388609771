// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoom-container {
    position: relative;
    overflow: hidden;
    cursor: zoom-in; /* Change cursor to zoom-in */
  }
  
  .zoom-image {
    display: block;
    transition: transform 0.5s ease;
    width: 100%;
    height: auto;
    transform-origin: center center;
  }
  
  .zoom-container:hover .zoom-image {
    transform: scale(2); /* Adjust the scale as needed */
  }
  
  .zoom-container:hover .zoom-image {
    transform: scale(2);
  }
  
  .zoom-container:hover .zoom-image {
    transform: scale(2);
    cursor: zoom-in;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/zoom.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe,EAAE,6BAA6B;EAChD;;EAEA;IACE,cAAc;IACd,+BAA+B;IAC/B,WAAW;IACX,YAAY;IACZ,+BAA+B;EACjC;;EAEA;IACE,mBAAmB,EAAE,+BAA+B;EACtD;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".zoom-container {\r\n    position: relative;\r\n    overflow: hidden;\r\n    cursor: zoom-in; /* Change cursor to zoom-in */\r\n  }\r\n  \r\n  .zoom-image {\r\n    display: block;\r\n    transition: transform 0.5s ease;\r\n    width: 100%;\r\n    height: auto;\r\n    transform-origin: center center;\r\n  }\r\n  \r\n  .zoom-container:hover .zoom-image {\r\n    transform: scale(2); /* Adjust the scale as needed */\r\n  }\r\n  \r\n  .zoom-container:hover .zoom-image {\r\n    transform: scale(2);\r\n  }\r\n  \r\n  .zoom-container:hover .zoom-image {\r\n    transform: scale(2);\r\n    cursor: zoom-in;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
