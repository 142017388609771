import React from 'react';
// import UserSidebar from './UserSidebar';
import { Outlet } from 'react-router-dom';
import '../../styles/MainLayout.css';

const UserLayout = () => {
  return (
    <div className="">
      {/* <UserSidebar /> */}
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default UserLayout;

