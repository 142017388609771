import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const serverUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${serverUrl}/api/users`);
                setUsers(response.data);
                setFilteredUsers(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching users:', error);
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const filtered = users.filter(user =>
            user.username?.toLowerCase().includes(search.toLowerCase()) ||
            user.email?.toLowerCase().includes(search.toLowerCase()) ||
            user.phone_number?.toLowerCase().includes(search.toLowerCase()) ||
            user.branch_code?.toLowerCase().includes(search.toLowerCase()) ||
            user.branch_name?.toLowerCase().includes(search.toLowerCase())

            // user.role_n?.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [search, users]);

    const columns = [
        // { name: 'ID', selector: row => row.id, sortable: true },
        { name: 'Name', selector: row => row.username, sortable: true },
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'Phone', selector: row => row.phone_number, sortable: true },
        // { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'Branch Code', selector: row => row.branch_code, sortable: true },
        { name: 'Branch Name', selector: row => row.branch_name, sortable: true },

        // { name: 'Role', selector: row => row.roleName, sortable: true }
    ];

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-header bg-primary text-white">
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    User List
                </div>
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="form-control"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredUsers}
                        progressPending={loading}
                        pagination
                        responsive
                        striped
                    />
                </div>
            </div>
        </div>
    );
};

export default UserList;
