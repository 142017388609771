import React, { useState, useEffect } from "react";
import Autocomplete from '../Common/AutoComplete';

function Medicine({ formData, setFormData, errors, request }) {
  const serverUrl = process.env.REACT_APP_API_URL;


  // const [fields, setFields] = useState(formData.molecule || [{ molecule: "", strength: "", unit: "", groupCode: "" }]);
  const [fields, setFields] = useState(Array.isArray(formData.molecule) ? formData.molecule : [{ molecule: "", strength: "", unit: "" }]);
  useEffect(() => {
    if (fields.length === 0) {
      setFields([{ molecule: "", strength: "", unit: "" }]);
      setFormData(prevState => ({
        ...prevState,
        molecule: fields
      }));
    }

  }, [fields, setFormData]);

  const handleChange = (index, e) => {
    const { id, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index][id] = value;
    setFields(updatedFields);
    console.log(updatedFields)
    setFormData(prevState => ({
      ...prevState,
      molecule: updatedFields
    }));
  };

  const handleAddFields = () => {
    setFields([...fields, { molecule: "", strength: "", unit: "" }]);
  };

  const handleRemoveFields = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  return (
    <>
      <div className="row mt-2">
        {fields.map((field, index) => (
          <div key={index} className="col-12 mb-3">
            <div className="row">
              <div className="col-12 text-end">
                <button type="button" className="btn btn-success" onClick={handleAddFields}>
                  +
                </button>
                {fields.length > 1 && (
                  <button type="button" className="btn btn-danger" onClick={() => handleRemoveFields(index)}>
                    -
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">

                  <Autocomplete url={`${serverUrl}/api/autocomplete/molecule`}
                    value={field.molecule || ''}
                    onChange={(e) => handleChange(index, e)}
                    label="Molecule"
                    id="molecule"
                    defaultValue={field.molecule}
                  />
                  {errors.molecule && (
                    <span className="error-message text-danger">
                      {errors.molecule}
                    </span>
                  )}
                  {/* <select className="form-control" id="molecule" value={field.molecule} onChange={(e) => handleChange(index, e)}>
                    <option value="" className="text-primary">
                      Select molecule
                    </option>
                    <option value="Molecule1" className="text-primary">
                      Molecule1
                    </option>
                  </select> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="strength" className="">
                    Strength<span> *</span>
                  </label>
                  <input className="form-control" id="strength" value={field.strength} onChange={(e) => handleChange(index, e)}
                  />
                  {/* <select className="form-control" id="strength" value={field.strength} onChange={(e) => handleChange(index, e)}>
                    <option value="" className="text-primary">
                      Select strength
                    </option>
                    <option value="Strength1" className="text-primary">
                      Strength1
                    </option>                    
                  </select> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Autocomplete url={`${serverUrl}/api/autocomplete/unit`} value={field.unit || ''} onChange={(e) => handleChange(index, e)} label="Unit" id="unit" defaultValue={field.unit} />
                  {errors.unit && (
                    <span className="error-message text-danger">
                      {errors.unit}
                    </span>
                  )}

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>

  );
}

export default Medicine;
