import React, { useState } from "react";

function Fmcg({formData, setFormData, errors}) {
  // const [formData, setFormData] = useState({
  //   Eancode: "",
  //   Barcode: ""
  // });
  // useEffect(() => {
  //   if (fields.length === 0) {
  //     setFields([{ molecule: "", strength: "", unit: ""}]);
  //     setFormData(prevState => ({
  //       ...prevState,
  //       molecule: fields
  //     }));
  //   }
    
  // }, [fields, setFormData]);


  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'Eancode' && value < 0) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: 0
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: value
      }));
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-md-4">
        <div className="mb-3">
          <label htmlFor="Eancode" className="form-label">
            Ean code<span> *</span>
          </label>
          <input
            type="number"
            className="form-control"
            id="Eancode"
            placeholder="Enter Your Ean Code"
            value={formData.Eancode}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="mb-3">
          <label htmlFor="Barcode" className="form-label">
            Print Barcode <span> *</span>
          </label>
          <select
            className="form-control"
            id="Barcode"
            value={formData.Barcode}
            onChange={handleChange}
          >
            <option value="" disabled>Select Barcode</option>
            <option value="1">Generate Barcode</option>
            <option value="2">Block Barcode</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Fmcg;
