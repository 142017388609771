import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import swal from "sweetalert";

function Userform() {
  const [errors, setErrors] = useState({});
  const serverUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    branchcode: '',
    branchemailid: '',
    barnchmobileno: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.branchcode) {
      newErrors.branchcode = "Branch Code is required";
    }

    if (!formData.branchemailid) {
      newErrors.branchemailid = "Branch Email Id is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.branchemailid)) {
      newErrors.branchemailid = "Email is invalid";
    }

    if (!formData.barnchmobileno) {
      newErrors.barnchmobileno = "Branch Mobile Number is required";
    } else if (!/^\d{10}$/.test(formData.barnchmobileno)) {
      newErrors.barnchmobileno = "Mobile Number is invalid";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const submissionData = new FormData();
        for (const key in formData) {
          if (formData[key] !== null) {
            submissionData.append(key, formData[key]);
          }
        }

        const formDataObject = Object.fromEntries(submissionData.entries());
        console.log('FormData as Object:', formDataObject);

        // const apiUrl = "${serverUrl}/api/auth/register";

        // const response = await axios.post(apiUrl, formDataObject);

        swal({
          title: "Success!",
          text: "User created successfully!",
          icon: "success",
          button: "OK",
        });
        // console.log("Form Data Submitted:", response.data);
      } catch (error) {
        console.error("Error submitting form data:", error);

        // Determine the error message
        let errorMessage = "An error occurred while submitting the form.";
        if (error.response) {
          // Server responded with a status other than 200 range
          errorMessage = error.response.data.message || "Server error. Please try again later.";
        } else if (error.request) {
          // Request was made but no response received
          errorMessage = "No response from server. Please check your network connection.";
        } else {
          // Something else caused the error
          errorMessage = error.message;
        }

        // Show error alert
        swal({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          button: "OK",
        });
      }

    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="card bg-body rounded mt-4">
            <div className="card-header text-start text-white h2" style={{ backgroundColor: 'blue' }}>User Form</div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="branchcode" className="form-label">
                    Branch Code <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.branchcode ? 'is-invalid' : ''}`}
                    id="branchcode"
                    placeholder="Enter Branch Code"
                    value={formData.branchcode}
                    onChange={handleChange}
                  />
                  {errors.branchcode && <div className="invalid-feedback">{errors.branchcode}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="branchemailid" className="form-label">
                    Branch Email Id <span>*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${errors.branchemailid ? 'is-invalid' : ''}`}
                    id="branchemailid"
                    placeholder="Enter Branch Email Id"
                    value={formData.branchemailid}
                    onChange={handleChange}
                  />
                  {errors.branchemailid && <div className="invalid-feedback">{errors.branchemailid}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="barnchmobileno" className="form-label">
                    Branch Mobile Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.barnchmobileno ? 'is-invalid' : ''}`}
                    id="barnchmobileno"
                    placeholder="Enter Branch Mobile Number"
                    value={formData.barnchmobileno}
                    onChange={handleChange}
                  />
                  {errors.barnchmobileno && <div className="invalid-feedback">{errors.barnchmobileno}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Enter Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    id="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary" style={{ width: '180px' }}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userform;
