// components/Common/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from './SessionContext';
import axios from 'axios';

const Logout = ({ setuserRole }) => {
  const { setSessionValues } = useSession();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Call your API to handle server-side logout if necessary
        // await axios.post('${serverUrl}/api/auth/logout', {}, { withCredentials: true });

        // Clear session values
        setSessionValues({
          userRole: '',
          category: '',
        });

        // Clear localStorage
        localStorage.removeItem('branchCode');
        localStorage.removeItem('email');
        localStorage.removeItem('userName');

        // Clear userRole state and redirect to login
        setuserRole('');
        navigate('/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    performLogout();
  }, [setSessionValues, setuserRole, navigate]);

  return null;
};

export default Logout;
