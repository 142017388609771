const validateMedicine = (formData) => {
    const errors = {};

    console.log(formData, 'medicine')


    if (!formData.molecule || formData.molecule.length === 0) {
        errors.molecule = 'At least one molecule is required';
    } else {
        formData.molecule.forEach((field, index) => {
            if (!field.molecule) {
                errors[`molecule[${index}].molecule`] = 'Molecule is required';
            }
            if (!field.strength) {
                errors[`molecule[${index}].strength`] = 'Strength is required';
            }
            if (!field.unit) {
                errors[`molecule[${index}].unit`] = 'Unit is required';
            }
        });
    }

    return errors;
};

const validateFmcg = (formData) => {
    const errors = {};

    if (!formData.Eancode) {
        errors.Eancode = 'EAN Code is required';
    }
    if (!formData.Barcode) {
        errors.Barcode = 'Bar Code is required';
    }

    return errors;
};

const validateOprs = (formData) => {
    const errors = {};

    if (!formData.discountSlab) {
        errors.discountSlab = 'Discount Slab is required';
    }
    if (!formData.allowDiscount) {
        errors.allowDiscount = 'Allow Discount is required';
    }
    if (!formData.groupCode) {
        errors.groupCode = 'Group Code is required';
    }

    return errors;
};

const validateSurgical = (formData) => {
    const errors = {};

    if (!formData.discountSlab) {
        errors.discountSlab = 'Discount Slab is required';
    }
    if (!formData.allowDiscount) {
        errors.allowDiscount = 'Allow Discount is required';
    }
    if (!formData.groupCode) {
        errors.groupCode = 'Group Code is required';
    }

    return errors;
};
