// import React from 'react';
import React, { useState,useEffect } from 'react';
// import UserSidebar from './UserSidebar';
// import AdminSidebar from './AdminSidebar';
import '../../styles/MainLayout.css';
import { Outlet } from 'react-router-dom';
import '../../styles/MainLayout.css';
// import Sidebar from "./Sidebar";
import Sidebar from "./../Admin/Sidebar";
import useBodyClass from '../utility/useBodyClass';
// import '../../styles/materialize-bootstrap.css';


const AdminLayout = ({ children }) => {
    // console.log(children);
    useBodyClass('admin-layout');
    useEffect(() => {
      const inputs = document.querySelectorAll('.form-control');
      inputs.forEach(input => {
        if (input.value) {
          input.classList.add('form-label-active');
        }
      });
  
      const handleFocus = (event) => {
        const label = event.target.nextElementSibling;
        if (label) {
          label.classList.add('form-label-active');
        }
      };
  
      const handleBlur = (event) => {
        const label = event.target.nextElementSibling;
        if (label && !event.target.value) {
          label.classList.remove('form-label-active');
        }
      };
  
      inputs.forEach(input => {
        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);
      });
  
      return () => {
        inputs.forEach(input => {
          input.removeEventListener('focus', handleFocus);
          input.removeEventListener('blur', handleBlur);
        });
      };
    }, []);

    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
      setIsCollapsed(!isCollapsed);
    };
  
    return (
      <div className={`main-layout ${isCollapsed ? 'collapsed' : ''}`}>
        <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    );
};

export default AdminLayout;
