import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import '../../styles/zoom.css';
import Autocomplete from '../Common/AutoComplete';
import { Accordion, Form, FloatingLabel } from 'react-bootstrap';
import '../../styles/ProductDetails.css';
import Medicine from './Medicine';
import Fmcg from './Fmcg';
import Oprs from './Oprs';
import Surgical from './Surgical';
import { useSession } from './../Common/SessionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import axios from 'axios';
import 'select2';
import 'select2/dist/css/select2.min.css';
import $ from 'jquery';
import Swal from 'sweetalert2';
import heic2any from 'heic2any';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


const ItemDetails = ({ formData, setFormData, handleChange, handleCategoryChange, errors, request, isMedicine, isFmcg, isSurgical, isOPRS, similarItems, setSimilarItems, dropdownOpen, setDropdownOpen }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const isReadOnly = request.status === 'Rejected';
  // const [groupCodesOptions, setGroupCodesOptions] = useState('');
  // const handleGroupCodeChange = (e) => {
  //   const selectedGroupCode = e.target.value;
  //   document.getElementById('selectedGroupCode').value = selectedGroupCode;
  // };
  const [loading, setLoading] = useState(false);
  const { sessionData } = useSession();
  const initialImages = {
    invoiceImage: { url: request.media.invoiceImage, downloadUrl: request.media.invoiceImage },
    frontImage: { url: request.media.frontImage, downloadUrl: request.media.frontImage },
    backImage: { url: request.media.backImage, downloadUrl: request.media.backImage },
    leftImage: { url: request.media.leftImage, downloadUrl: request.media.leftImage },
    rightImage: { url: request.media.rightImage, downloadUrl: request.media.rightImage },
  };

  const [images, setImages] = useState(initialImages);
  const [isLoading, setIsLoading] = useState(true);
  const renderSubForm = () => {
    console.log('RENDER FORM');
    console.log(request.main_category);
    let element = '';
    switch (parseInt(request.main_category)) {
      case 2: // Medicine
        console.log('Medicine');
        element =
          <Medicine formData={formData} setFormData={setFormData} handleChange={handleChange} errors={errors} />;
        break;
      case 1: // Surgical
        // element =
        //   <Surgical formData={formData} setFormData={setFormData} handleChange={handleChange} errors={errors} />;
        break;
      case 3: // FMCG Food
        element = <Fmcg formData={formData} setFormData={setFormData} handleChange={handleChange} errors={errors} />;
        break;
      case 4: // FMCG Non Food
        element = <Fmcg formData={formData} setFormData={setFormData} handleChange={handleChange} errors={errors} />;
        break;
      case 5: // OPRS
        // element =
        //   <Oprs formData={formData} setFormData={setFormData} handleChange={handleChange} errors={errors} />;
        break;
      default:
        return null;
    }
    return element;
  };

  useEffect(() => {
    const convertHeicImages = async () => {
      const newImages = { ...initialImages };

      for (let [key, image] of Object.entries(newImages)) {
        if (image.url && image.url.endsWith('.heic')) {
          console.log(`Fetching HEIC image for ${key}: ${image.url}`);

          try {
            const response = await fetch(image.url);
            if (!response.ok) {
              console.error(`Failed to fetch ${key}: ${response.statusText} (status: ${response.status})`);
              continue;
            }
            const heicBlob = await response.blob();
            const convertedBlob = await heic2any({ blob: heicBlob, toType: 'image/jpeg' });
            const convertedImageUrl = URL.createObjectURL(convertedBlob);

            newImages[key] = { url: convertedImageUrl, downloadUrl: image.url };
          } catch (error) {
            console.error(`Failed to convert ${key}:`, error);
          }
        }
      }
      setImages(newImages);
      setIsLoading(false);
    };

    convertHeicImages();
  }, []);

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className='row mt-4'>
        <div className='card p-4'>
          <h3>User Information</h3>
          <div className='card-body'>
            <div className='row'>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="requestid" className="form-label">
                    Request Id <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="requestid" placeholder="Enter Your Request Id" value={request.req_id} onChange={handleChange} readOnly />
                  {errors.requestid && (
                    <span className="error-message text-danger ">
                      {errors.requestid}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="branchCode" className="form-label">
                    Branch Code <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="branchCode" placeholder="Enter Your Branch Code" value={request.branch_code} readOnly onChange={handleChange} />
                  {errors.branchCode && (
                    <span className="error-message text-danger ">
                      {errors.branchCode}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="Email" className="form-label">
                    Email <span> *</span>
                  </label>
                  <input type="email" className="form-control capital" id="Email" placeholder="Enter Your Email" value={request.user_information.store_email} onChange={handleChange} readOnly />
                  {errors.Email && (
                    <span className="error-message text-danger ">
                      {errors.Email}
                    </span>
                  )}
                </div>
              </div>

            </div>
            <div className='row'>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="Mobileno" className="form-label ">
                    Supplier Mobile No <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="Mobileno" placeholder="Enter Your Supplier Mobile No" value={request.user_information.s_mobile_no} onChange={handleChange} readOnly />
                  {errors.Mobileno && (
                    <span className="error-message text-danger">
                      {errors.Mobileno}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="suppliername" className="form-label">
                    Supplier Name <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="suppliername" placeholder="Enter Your Supplier Name" value={request.user_information.s_name} onChange={handleChange} readOnly />
                  {errors.suppliername && (
                    <span className="error-message text-danger">
                      {errors.suppliername}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="ContactName" className="form-label">
                    Store Contact Person Name <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="ContactName" placeholder="Enter Your Name" value={request.user_information.store_contact_name} onChange={handleChange} readOnly />
                  {errors.ContactName && (
                    <span className="error-message text-danger">
                      {errors.ContactName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="Contactno" className="form-label">
                    Store Contact No <span> *</span>
                  </label>
                  <input type="text" className="form-control capital" id="Contactno" placeholder="Enter Your Contact No" value={request.user_information.store_contact_no} onChange={handleChange} readOnly />
                  {errors.Contactno && (
                    <span className="error-message text-danger">
                      {errors.Contactno}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='card p-4'>
          <h3>Product Information</h3>
          <div className='row'>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Product Name<span> *</span>
                </label>
                <input type="text" className="form-control capital" id="name" placeholder="Enter Your Product Name" value={formData.name} onChange={handleChange} readOnly={isReadOnly} />
                {errors.name && (
                  <span className="error-message text-danger">
                    {errors.name}
                  </span>
                )}

              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Product Full Name <span> *</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control capital"
                    id="fullName"
                    placeholder="Enter Your Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    aria-label="Text input with dropdown button"
                  />
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {similarItems.length}
                  </button>
                  <ul className={`dropdown-menu dropdown-menu-end ${dropdownOpen ? 'show' : ''}`}>
                    {similarItems.length > 0 ? (
                      similarItems.map((item, index) => (
                        <li key={index}>
                          <span className="dropdown-item">{item.c_code} - {item.c_name}</span>
                        </li>
                      ))
                    ) : (
                      <li><span className="dropdown-item">No similar items found</span></li>
                    )}
                  </ul>
                </div>
                {errors.fullName && (
                  <span className="error-message text-danger">
                    {errors.fullName}
                  </span>
                )}
              </div>
            </div>
            {sessionData.userRole === 'admin' ? (
              <>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="brandName" className="form-label">
                      Brand Name<span> *</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={formData.brandName || ''}
                        placeholder="Enter your brand name"
                        aria-label="Enter your brand name"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={() => {
                          const userName = localStorage.getItem('userName') || 'N/A';
                          const brandNameWithHashes = formData.brandName || '';
                          const brandName = brandNameWithHashes.split('|')[1] || '';
                          const shortCode = brandName.substring(0, 4).toUpperCase();

                          swal({
                            title: 'Brand Details',
                            content: {
                              element: 'div',
                              attributes: {
                                innerHTML: `
                    <div>
                      <div class="form-group">
                        <label>Username:</label>
                        <input type="text" class="form-control" value="${userName}" readonly />
                      </div>
                      <div class="form-group">
                        <label>Brand Name:</label>
                        <input type="text" class="form-control" value="${brandName}" readonly />
                      </div>
                      <div class="form-group">
                        <label>Short Code:</label>
                        <input type="text" class="form-control" value="${shortCode}" readonly />
                      </div>
                    </div>
                  `
                              }
                            },
                            buttons: {
                              confirm: {
                                text: 'Submit',
                                closeModal: true
                              }
                            }
                          }).then((willSubmit) => {
                            if (willSubmit) {
                              const dataToSend = {
                                userName,
                                brandName,
                                shortCode
                              };

                              axios.post(`${serverUrl}/api/brand-details`, dataToSend)
                                .then(response => {
                                  console.log('Data sent successfully:', response.data);
                                  // swal('Success', 'Brand Name created successfully!', 'success');

                                  // if (response.data && response.data.Code) {
                                  //   const updatedBrandName = `${response.data.Code}|${brandName}`;
                                  //   setFormData(prevState => ({
                                  //     ...prevState,
                                  //     brandName: updatedBrandName
                                  //   }));
                                  // }

                                  if (response.data.StatusCode) {
                                    swal('Success', response.data.Response, 'success');

                                    if (response.data && response.data.Code) {
                                      const updatedBrandName = `${response.data.Code}|${brandName}`;
                                      setFormData(prevState => ({
                                        ...prevState,
                                        brandName: updatedBrandName
                                      }));
                                    }
                                  }
                                  else if (response.data.StatusCode === "400") {
                                    swal('Failure', response.data.Response, 'error')
                                  }
                                })
                                .catch(error => {
                                  console.error('Error sending data:', error);
                                  swal('Error', 'There was an error submitting your data.', 'error');
                                });
                            }
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="marketedBy" className="form-label">
                      Marketed By<span> *</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter marketed by"
                        aria-label="Enter marketed by"
                        aria-describedby="button-addon2"
                        value={formData.marketedBy}
                        readOnly
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={async () => {
                          const userName = (localStorage.getItem('userName') || 'N/A').toUpperCase();
                          const marketedByWithHashes = formData.marketedBy || '';
                          const marketedBy = marketedByWithHashes.split('|')[1] || '';
                          const shortCode = marketedBy.substring(0, 4).toUpperCase();
                          setLoading(true);

                          try {
                            const response = await axios.get(`${serverUrl}/api/mfac-group-codes`);
                            const data = response.data.detail;
                            const groupCodesOptions = data.map(item => `<option value="${item.C_CODE}">${item.C_CODE} - ${item.c_name}</option>`).join('');

                            MySwal.fire({
                              title: 'Marketed By Details',
                              html: `
                                <div class="form-group">
                                  <label>Username:</label>
                                  <input type="text" class="form-control" value="${userName}" readonly />
                                </div>
                                <div class="form-group">
                                  <label>Marketed By:</label>
                                  <input type="text" class="form-control" value="${marketedBy}" readonly />
                                </div>
                                <div class="form-group">
                                  <label>Short Code:</label>
                                  <input type="text" class="form-control" value="${shortCode}" readonly />
                                </div>
                                <div class="form-group">
                                  <label>Group Code:</label>
                                  <select id="groupCode" class="form-control">
                                    ${groupCodesOptions}
                                  </select>
                                </div>
                              `,
                              showCancelButton: true,
                              confirmButtonText: 'Submit',
                              focusConfirm: false,
                              preConfirm: () => {
                                const groupCode = Swal.getPopup().querySelector('#groupCode').value;
                                // console.log(groupCode, 'GRP CODE');
                                if (!groupCode) {
                                  Swal.showValidationMessage(`Please select a group code`);
                                }
                                return { groupCode };
                              }
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                const groupCode = result.value;

                                const dataToSend = {
                                  userName,
                                  marketedBy,
                                  shortCode,
                                  groupCode
                                };

                                try {
                                  const response = await axios.post(`${serverUrl}/api/marketed-by-details`, dataToSend);

                                  if (response.data.StatusCode) {
                                    MySwal.fire('Success', response.data.Response, 'success');

                                    if (response.data && response.data.Code) {
                                      const updatedMarketedBy = `${response.data.Code}|${marketedBy}`;
                                      setFormData(prevState => ({
                                        ...prevState,
                                        marketedBy: updatedMarketedBy
                                      }));
                                    }
                                  } else if (response.data.StatusCode === "400") {
                                    MySwal.fire('Failure', response.data.Response, 'error');
                                  }
                                } catch (error) {
                                  if (error.response && error.response.status === 400) {
                                    MySwal.fire('Error', error.response.data.Response, 'error');
                                  } else {
                                    console.error('Error sending data:', error);
                                    MySwal.fire('Error', 'There was an error submitting your data.', 'error');
                                  }
                                }
                              }
                            });
                          } catch (error) {
                            console.error('Error fetching group codes:', error);
                            MySwal.fire('Error', 'There was an error fetching group codes.', 'error');
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-3">
                  <div className="mb-3">
                    <Autocomplete
                      url={`${serverUrl}/api/autocomplete/brand`}
                      value={formData.brandName || ''}
                      onChange={handleChange}
                      label="Brand Name"
                      id="brandName"
                      defaultValue={formData.brandName}
                      readOnly={isReadOnly}
                      isBrandName={true}
                    />
                    {errors.brandName && (
                      <span className="error-message text-danger">
                        {errors.brandName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <Autocomplete
                      url={`${serverUrl}/api/autocomplete/marketed-by`}
                      value={formData.marketedBy}
                      onChange={handleChange}
                      label="Marketed By"
                      id="marketedBy"
                      readOnly={isReadOnly}
                      isMfgName={true}
                    />
                    {errors.marketedBy && (
                      <span className="error-message text-danger">
                        {errors.marketedBy}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}


            {/* <div className="col-md-3">
            <div className="mb-3">
              <Autocomplete
                url="${serverUrl}/api/autocomplete/brand"
                value={formData.brandName || ''}
                onChange={handleChange}
                label="Brand Name"
                id="brandName"
                defaultValue={formData.brandName}
                readOnly={isReadOnly}
                isBrandName={true}
              />
              {errors.brandName && (
                <span className="error-message text-danger">
                  {errors.brandName}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <Autocomplete
                url="${serverUrl}/api/autocomplete/marketed-by"
                value={formData.marketedBy}
                onChange={handleChange}
                label="Marketed By"
                id="marketedBy"
                readOnly={isReadOnly}
                isMfgName={true}
              />
              {errors.marketedBy && (
                <span className="error-message text-danger">
                  {errors.marketedBy}
                </span>
              )}
            </div>
          </div> */}


            <div className="col-md-3">
              <div className="mb-3">
                {/* <label htmlFor="pack" className="form-label">
                Pack:<span> *</span>
              </label>
              <select className="form-control capital" id="pack" value={formData.pack} onChange={handleChange}>
                <option value="">Select Pack</option>
                <option value="Option1">Option 1</option>
                <option value="Option2">Option 2</option>
              </select> */}
                <Autocomplete url={`${serverUrl}/api/autocomplete/pack`} value={formData.pack} onChange={handleChange} label="Pack" id="pack" readOnly={isReadOnly} />
                {errors.pack && (
                  <span className="error-message text-danger">
                    {errors.pack}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="qtyPerPack" className="form-label">
                  Qty/Pack<span> *</span>
                </label>
                <input type="number" className="form-control capital" id="qtyPerPack" min="1" placeholder="Enter Your Qty/Pack" value={formData.qtyPerPack} onChange={handleChange} readOnly={isReadOnly} />
                {errors.qtyPerPack && (
                  <span className="error-message text-danger">
                    {errors.qtyPerPack}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="mrpPerPack" className="form-label">
                  Mrp Pack<span> *</span>
                </label>
                <input type="number" className="form-control capital" id="mrpPerPack" placeholder="Enter Your MRP Pack" value={formData.mrpPerPack} onChange={handleChange} readOnly={isReadOnly} />
                {errors.mrpPerPack && (
                  <span className="error-message text-danger">
                    {errors.mrpPerPack}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="puRatePerPack" className="form-label">
                  Purchase Rate<span> *</span>
                </label>
                <input type="number" className="form-control capital" id="puRatePerPack" placeholder="Enter Your PU Rate Pack " value={formData.puRatePerPack} onChange={handleChange} readOnly={isReadOnly} />
                {errors.puRatePerPack && (
                  <span className="error-message text-danger">
                    {errors.puRatePerPack}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-3">
              <div className="mb-3">
                <Autocomplete url={`${serverUrl}/api/autocomplete/category`} value={formData.category} onChange={handleCategoryChange} label="Category (L3)" id="category" isClass={true} readOnly={isReadOnly} />
                {errors.category && (
                  <span className="error-message text-danger">
                    {errors.category}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="head" className="form-label">
                  Head (L2) <span> </span>
                </label>
                <select className='form-control'>
                  <option value={formData.head}>{formData.headName}</option>
                </select>
                {/* <Autocomplete
                        url="${serverUrl}/api/autocomplete/head"
                        value={formData.head}
                        // onChange={(value) => setFormData({ ...formData, head: value })}
                        label="Head"
                        id="head"
                    /> */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="class" className="form-label">
                  Class (L1) <span> </span>
                </label>
                <select className='form-control'>
                  <option value={formData.class}>{formData.className}</option>
                </select>
                {/* <Autocomplete
                        url="${serverUrl}/api/autocomplete/class"
                        value={formData.class}
                        // onChange={(value) => setFormData({ ...formData, class: value })}
                        label="Class"
                        id="class"
                    /> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                {/* <label htmlFor="gst" className="form-label">
                GST <span> *</span>
              </label>
              <select className="form-control capital" id="gst" value={formData.gst} onChange={handleChange}>
                <option value="">Select GST %</option>
                <option value="Option1">Option 1</option>
                <option value="Option2">Option 2</option>
              </select> */}
                <Autocomplete url={`${serverUrl}/api/autocomplete/gst`} value={formData.gst} onChange={handleChange} label="GST" id="gst" readOnly={isReadOnly} />
                {errors.gst && (
                  <span className="error-message text-danger">
                    {errors.gst}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                {/* <label htmlFor="hsnSacNo" className="form-label">
                HSN/SAC No<span> *</span>:
              </label>
              <select className="form-control capital" id="hsnSacNo" value={formData.hsnSacNo} onChange={handleChange}>
                <option value="">Select HSN/SAC No</option>
                <option value="Option1">Option 1</option>
                <option value="Option2">Option 2</option>
              </select> */}
                <Autocomplete url={`${serverUrl}/api/autocomplete/hsnsac`} value={formData.hsnSacNo} onChange={handleChange} label="HSN/SAC No" id="hsnSacNo" isHSNSAC={true} readOnly={isReadOnly} />
                {errors.hsnSacNo && (
                  <span className="error-message text-danger">
                    {errors.hsnSacNo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="storage" className="form-label">
                  Storage /Temperature<span> *</span>
                </label>
                <select className="form-select capital" id="storage" value={formData.storage} onChange={handleChange} readOnly={isReadOnly}>
                  <option value="">Select Storage /Temperature</option>
                  <option value="Dry">Dry Storage</option>
                  <option value="Cold">Cold Storage</option>
                </select>
                {errors.storage && (
                  <span className="error-message text-danger">
                    {errors.storage}
                  </span>
                )}
              </div>
            </div>
            {/* {isMedicine && (
            
          )} */}

            {/* <hr style={{ backgroundColor: 'black' }} /> */}

          </div>
          {isMedicine && (
            <div className='row'>
              <div className="col-md-3">
                <div className="mb-3">
                  {/* <label htmlFor="schedule" className="form-label">
                Schedule<span> *</span>
              </label>
              <select className="form-control capital" id="schedule" value={formData.schedule} onChange={handleChange}>
                <option value="">Select Schedule</option>
                <option value="Option1">Option 1</option>
                <option value="Option2">Option 2</option>
              </select> */}
                  <Autocomplete url={`${serverUrl}/api/autocomplete/schedule`} value={formData.schedule} onChange={handleChange} label="Schedule" id="schedule" readOnly={isReadOnly} />
                  {errors.schedule && (
                    <span className="error-message text-danger">
                      {errors.schedule}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="strength" className="form-label">
                    Strength<span>*</span>
                  </label>
                  <input type="text" className="form-control capital" id="strength" placeholder="Enter Your Strength" value={formData.strength} onChange={handleChange} readOnly={isReadOnly} />
                  {errors.strength && (
                    <span className="error-message text-danger">
                      {errors.strength}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-3">
                  {/* <label htmlFor="Dosage" className="form-label">
                  Dosage form /Pack Type:<span> *</span>
                </label>
                <select className="form-control capital" id="Dosage" value={formData.publish} onChange={handleChange} readOnly>
                  <option value="">Select Dosage Form /pack Type</option>
                  <option value="Option1">Option 1</option>
                  <option value="Option2">Option 2</option>
                </select> */}
                  <Autocomplete url={`${serverUrl}/api/autocomplete/pack-type`} value={formData.Dosage} onChange={handleChange} label="Dosage form /Pack Type" id="Dosage" readOnly={isReadOnly} />
                  {errors.Dosage && (
                    <span className="error-message text-danger">
                      {errors.Dosage}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* second row end */}
          <div className='row'>
            <div className="col-md-3">
              <div className="mb-3">
                <Autocomplete url={`${serverUrl}/api/autocomplete/item-type`} value={formData.itemCohort} onChange={handleChange} label="Item Type/Cohort" id="itemCohort" readOnly={isReadOnly} />
                {errors.itemCohort && (
                  <span className="error-message text-danger">
                    {errors.itemCohort}
                  </span>
                )}
              </div>
            </div>
            {/* {isMedicine && (
           
          )} */}

            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="sellingType" className="form-label">
                  Selling Type<span> *</span>
                </label>
                <select className="form-select capital" id="sellingType" value={formData.sellingType} onChange={handleChange} readOnly>
                  <option value="">Select Selling Type</option>
                  <option value="0">Saleable</option>
                  <option value="1">Non Saleable</option>
                  {/* <option value="Option3">Non Saleble</option> */}
                  <option value="2">Raw Materials</option>
                  <option value="3">Semi Finish</option>
                </select>
                {errors.sellingType && (
                  <span className="error-message text-danger">
                    {errors.sellingType}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="returnType" className="form-label">
                  Return Type<span> *</span>
                </label>
                <select className="form-select capital" id="returnType" value={formData.returnType} onChange={handleChange} readOnly>
                  <option value="">Select Return Type</option>
                  <option value="0">Returnable</option>
                  <option value="1">Non Returnable To Supplier</option>
                  <option value="2">Non Returnable To Customers</option>
                  <option value="3">Non Returnable to Both</option>
                </select>
                {errors.returnType && (
                  <span className="error-message text-danger">
                    {errors.returnType}
                  </span>
                )}
              </div>
            </div>

          </div>

          <div className='row'>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="mfgDate" className="form-label">
                  MFG Date<span> *</span>
                </label>
                <input type="date" className="form-control capital" id="mfgDate" value={formData.mfgDate} onChange={handleChange} readOnly={isReadOnly} />
                {errors.mfgDate && (
                  <span className="error-message text-danger">
                    {errors.mfgDate}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="expDate" className="form-label">
                  EXP Date<span> *</span>
                </label>
                <input type="date" className="form-control capital" id="expDate" value={formData.expDate} onChange={handleChange} readOnly={isReadOnly} />
                {errors.expDate && (
                  <span className="error-message text-danger">
                    {errors.expDate}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="shelfLifeDays" className="form-label">
                  Shl.Life-Days<span> *</span>
                </label>
                <input type="text" className="form-control capital" id="shelfLifeDays" placeholder="Shelf Life in Days" value={formData.shelfLifeDays} onChange={handleChange} readOnly />
                {errors.shelfLifeDays && (
                  <span className="error-message text-danger">
                    {errors.shelfLifeDays}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='row'>
            {(isMedicine || isSurgical || isOPRS) && (
              // <div className='row'>
              <div className="col-md-3">
                <div className="mb-3">
                  {/* <label htmlFor="DiscountSlab" className="form-label">
                    Discount Slab<span> *</span>
                  </label>
                  <select className="form-control capital" id="Discountslab" value={formData.returnType} onChange={handleChange} readOnly>
                    <option value="">Select Discount Slab</option>
                    <option value="Option1">Option 1</option>
                    <option value="Option2">Option 2</option>
                  </select> */}
                  <Autocomplete url={`${serverUrl}/api/autocomplete/cont`} value={formData.discountSlab} onChange={handleChange} label="Discount Slab" id="discountSlab" />
                  {errors.discountSlab && (
                    <span className="error-message text-danger">
                      {errors.discountSlab}
                    </span>
                  )}
                </div>
              </div>
            )}

            {(isMedicine || isSurgical || isOPRS) && (
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="allowDiscount" className="form-label">
                    Allow Discount<span> *</span></label>
                  <select className="form-control"
                    id="allowDiscount"
                    value={formData.allowDiscount}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                  >
                    <option value="">Select Allow Discount</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>

            )}

            <div className="col-md-3">
              <div className="form-group">
                <Autocomplete url={`${serverUrl}/api/autocomplete/item-group`} value={formData.groupCode || ''} onChange={handleChange} label="Group code" id="groupCode" defaultValue={formData.groupCode} />
                {errors.groupCode && (
                  <span className="error-message text-danger">
                    {errors.groupCode}
                  </span>
                )}

              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="allowdiscount" className="form-label">
                  Publish On App/Web<span> *</span>
                </label>
                <select className="form-select capital" id="PublishOnApp" value={formData.PublishOnApp} onChange={handleChange} readOnly={isReadOnly}>
                  <option value="">Select Publish app/Web</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                {errors.PublishOnApp && (
                  <span className="error-message text-danger">
                    {errors.PublishOnApp}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="note" className="form-label">
                  Note
                </label>
                <input type="text" className="form-control capital" id="note" placeholder="Enter a Note or NA" value={formData.note} onChange={handleChange} />
                {errors.note && (
                  <span className="error-message text-danger">
                    {errors.note}
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* <div className='row'>

          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="allowdiscount" className="form-label">
                Publish On App/Web<span> *</span>
              </label>
              <select className="form-select capital" id="PublishOnApp" value={formData.PublishOnApp} onChange={handleChange} readOnly>
                <option value="">Select Publish app/Web</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              {errors.PublishOnApp && (
                <span className="error-message text-danger">
                  {errors.PublishOnApp}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="note" className="form-label">
                Note<span> </span>
              </label>
              <input type="text" className="form-control capital" id="note" placeholder="Enter a Note" value={formData.note} onChange={handleChange} />
              {errors.note && (
                <span className="error-message text-danger">
                  {errors.note}
                </span>
              )}
            </div>
          </div>
        </div> */}

        </div>




        <div className='card p-4' Style={(parseInt(request.main_category) == 2 || parseInt(request.main_category) == 3 || parseInt(request.main_category) == 4) ? '' : 'display:none'}>
          <h3>Category Information</h3>

          {renderSubForm()}
          {/* <Medicine formData={formData} handleChange={handleChange} errors={errors} />;
            <Surgical formData={formData} handleChange={handleChange} errors={errors} />;
            <Oprs formData={formData} handleChange={handleChange} errors={errors} />;
            <Fmcg formData={formData} handleChange={handleChange} errors={errors} />; */}
        </div>
        {/* <div className='card p-4'>
          <h3>Images</h3>

          <div className='row'>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="invoiceImage" className="form-label">
                  Product Invoice / Bill Image <span> *</span>
                </label>
                <div>
      

                  <InnerImageZoom src={request.media.invoiceImage} zoomSrc={request.media.invoiceImage} zoomType="hover" alt="Product Invoice" width="100%" />
                  <Link className='btn btn-success downloadbtn' to={request.media.invoiceImage} download>Download</Link>

                </div>
                {errors.invoiceImage && (
                  <span className="error-message text-danger">
                    {errors.invoiceImage}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="frontImage" className="form-label">
                  Product Front Image <span> *</span>
                </label>
                <div>
                  <InnerImageZoom src={request.media.frontImage} zoomSrc={request.media.frontImage} alt="Product Front" width="100%" />
                  <Link className='btn btn-success downloadbtn' to={request.media.frontImage} download>Download</Link>
                </div>
                {errors.frontImage && (
                  <span className="error-message text-danger">
                    {errors.frontImage}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="backImage" className="form-label">
                  Product Back Image <span> *</span>
                </label>
                <div>
                  <InnerImageZoom src={request.media.backImage} zoomSrc={request.media.backImage} alt="Product Back" width="100%" />
                  <Link className='btn btn-success downloadbtn' to={request.media.backImage} download>Download</Link>
                </div>
                {errors.backImage && (
                  <span className="error-message text-danger">
                    {errors.backImage}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3" Style={request.media.leftImage ? '' : 'display:none'}>
                <label htmlFor="leftImage" className="form-label">
                  Product Left Image
                </label>
                <div>
                  <InnerImageZoom src={request.media.leftImage} zoomSrc={request.media.leftImage} alt="Product Left" width="100%" />
                  <Link className='btn btn-success downloadbtn' to={request.media.leftImage} download>Download</Link>
                </div>
                {errors.leftImage && (
                  <span className="error-message text-danger">
                    {errors.leftImage}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3" Style={request.media.rightImage ? '' : 'display:none'}>
                <label htmlFor="rightImage" className="form-label">
                  Product Right Side Image
                </label>
                <div>
                  <InnerImageZoom src={request.media.rightImage} zoomSrc={request.media.rightImage} alt="Product Right" width="100%" />
                  <Link className='btn btn-success downloadbtn' to={request.media.rightImage} download>Download</Link>
                </div>
                {errors.rightImage && (
                  <span className="error-message text-danger">
                    {errors.rightImage}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <div className='card p-4'>
          <h3>Images</h3>
          <div className='row'>
            {/* {console.log(isLoading)} */}
            {isLoading ? ( // Check loading state
              <div className="col-12 text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <p>Loading images...</p>
              </div>
            ) : (
              Object.entries(images).map(([imgType, imgData]) => (
                <div className="col-md-3" key={imgType} style={imgData.url ? {} : { display: 'none' }}>
                  <div className="mb-3">
                    <label className="form-label">
                      {imgType === 'invoiceImage' ? 'Product Invoice / Bill Image' :
                        imgType === 'frontImage' ? 'Product Front Image' :
                          imgType === 'backImage' ? 'Product Back Image' :
                            imgType === 'leftImage' ? 'Product Left Image' :
                              'Product Right Side Image'}
                      <span> *</span>
                    </label>
                    <div>
                      {imgData.url ? (
                        <>
                          <InnerImageZoom
                            src={imgData.url}
                            zoomSrc={imgData.url}
                            alt={imgType.replace(/([A-Z])/g, ' $1').trim()}
                            width="100%"
                          />
                          <Link
                            className='btn btn-success downloadbtn'
                            to={imgData.downloadUrl}
                            // href={imgData.downloadUrl.split('/').pop()} // Should be the original HEIC AWS URL
                            // download={imgData.downloadUrl.split('/').pop()} // Extracts filename for proper download
                          >
                            Download
                          </Link>

                        </>
                      ) : (
                        <p className="text-danger">Image not available</p>
                      )}
                    </div>
                    {errors[imgType] && (
                      <span className="error-message text-danger">
                        {errors[imgType]}
                      </span>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {/* <div className='card p-4'>
          <h3>Action</h3>
          
            <div className='row mb-3'>
              <div className="d-flex justify-content-end">
                <div className="input-group w-auto">
                  <input type="text" className="form-control" placeholder="Reject" aria-label="Example input" aria-describedby="button-addon1" />
                  <button data-mdb-button-init data-mdb-ripple-init className="btn btn-primary" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div className='row mb-3'>
              <div className="d-flex justify-content-end">
                <div className="input-group w-auto">
                  <input type="text" className="form-control" placeholder="Accept" aria-label="Example input" aria-describedby="button-addon1" />
                  <button data-mdb-button-init data-mdb-ripple-init className="btn btn-primary" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div> */}




        {/* inner row end */}



      </div>
      {/* // main row end */}
    </div>
  );
};


export default ItemDetails;