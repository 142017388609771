// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-layout body {
    color: #566a77;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.authentication-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.authentication-inner {
    display: flex;
    width: 100%;
    height: 100%;
}

.authentication-bg {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.left {
    flex: 2 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(225deg, hsla(350, 79%, 45%, 1) 0%, hsla(351, 65%, 39%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#D01838", endColorstr="#A32235", GradientType=1);
}

.left img {
    max-width: 100%;
    max-height: 90vh;
    border-radius: 20px;
    /* box-shadow: 30px 30px #300D1F; */
    box-shadow: 3px 5px 18px 8px #300D1F;
}

.app-brand {
    text-align: center;
    margin-bottom: 1.5rem;
}

.btn-login {
    width: 100%;
}

.alert-danger {
    margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/LoginStyles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2FAA2F;IAC3F,kHAAkH;AACtH;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,mCAAmC;IACnC,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".public-layout body {\r\n    color: #566a77;\r\n    min-height: 100vh;\r\n    margin: 0;\r\n    padding: 0;\r\n    font-family: Arial, sans-serif;\r\n}\r\n\r\n.authentication-wrapper {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n}\r\n\r\n.authentication-inner {\r\n    display: flex;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.authentication-bg {\r\n    flex: 1;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: #fff;\r\n}\r\n\r\n.left {\r\n    flex: 2 1;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background: linear-gradient(225deg, hsla(350, 79%, 45%, 1) 0%, hsla(351, 65%, 39%, 1) 100%);\r\n    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#D01838\", endColorstr=\"#A32235\", GradientType=1);\r\n}\r\n\r\n.left img {\r\n    max-width: 100%;\r\n    max-height: 90vh;\r\n    border-radius: 20px;\r\n    /* box-shadow: 30px 30px #300D1F; */\r\n    box-shadow: 3px 5px 18px 8px #300D1F;\r\n}\r\n\r\n.app-brand {\r\n    text-align: center;\r\n    margin-bottom: 1.5rem;\r\n}\r\n\r\n.btn-login {\r\n    width: 100%;\r\n}\r\n\r\n.alert-danger {\r\n    margin-top: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
