import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BottomNav from '../Common/BottomNav';
import Navbar from '../Common/Navbar';
import StoreForm from './StoreForm';
import ReqList from './ReqList';
import { Container } from 'react-bootstrap';

const UserDashboard = () => {
  return (
    <div className='App'>
      <Navbar />
      <Container>
        <Routes>
          <Route path="/" element={<StoreForm />} />
          <Route path="/request-list" element={<ReqList />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Container>
      <BottomNav />
    </div>
  );
};

export default UserDashboard;
