// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:grey; */
  font-family: 'Arial', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-message {
  display: block;
  text-align:start;
  margin-top: 0.5rem;
  /* font-size: 0.5rem; */
}
.zoom:hover { /* IE 9 */ /* Safari 3-8 */
  transform: scale(1.1);
   }

.zoom1{
  height: 200px;
  background-color: #97c024 !important;
  
}
.zoom2{
  height: 200px;
  background-color: #4aa6e3 !important;
  
}
.zoom3{
  height: 200px;
  background-color: #00b82e !important;
  
}

.zoom4{

  height: 200px;
  background-color: #e87350!important;
}

.zoom5{
  height: 200px;
  background-color: #8e44ad !important;
}
.zoom6{
  background-color: #ff9900 !important
}
.zoom7{
  background-color: #e466ed!important
}
.zoom8{
  background-color:#42d6b7 !important
}



`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,2BAA2B;EAC3B,gCAAgC;AAClC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;AACzB;AACA,cAC6B,SAAS,EACL,eAAe;EAC9C,qBAAqB;GACpB;;AAEH;EACE,aAAa;EACb,oCAAoC;;AAEtC;AACA;EACE,aAAa;EACb,oCAAoC;;AAEtC;AACA;EACE,aAAa;EACb,oCAAoC;;AAEtC;;AAEA;;EAEE,aAAa;EACb,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,oCAAoC;AACtC;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":["body {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  /* background-color:grey; */\r\n  font-family: 'Arial', sans-serif;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n\r\n.error-message {\r\n  display: block;\r\n  text-align:start;\r\n  margin-top: 0.5rem;\r\n  /* font-size: 0.5rem; */\r\n}\r\n.zoom:hover {\r\n  -ms-transform: scale(1.1); /* IE 9 */\r\n  -webkit-transform: scale(1.1); /* Safari 3-8 */\r\n  transform: scale(1.1);\r\n   }\r\n\r\n.zoom1{\r\n  height: 200px;\r\n  background-color: #97c024 !important;\r\n  \r\n}\r\n.zoom2{\r\n  height: 200px;\r\n  background-color: #4aa6e3 !important;\r\n  \r\n}\r\n.zoom3{\r\n  height: 200px;\r\n  background-color: #00b82e !important;\r\n  \r\n}\r\n\r\n.zoom4{\r\n\r\n  height: 200px;\r\n  background-color: #e87350!important;\r\n}\r\n\r\n.zoom5{\r\n  height: 200px;\r\n  background-color: #8e44ad !important;\r\n}\r\n.zoom6{\r\n  background-color: #ff9900 !important\r\n}\r\n.zoom7{\r\n  background-color: #e466ed!important\r\n}\r\n.zoom8{\r\n  background-color:#42d6b7 !important\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
