import React, { useState } from "react";

function Oprs() {
  const [formData, setFormData] = useState({
    discountSlab: "",
    allowDiscount: "",
    groupCode: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div className="">      
      <div className="row mt-3">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="discountSlab" className="mb-2">
              Discount Slab<span> *</span>
            </label>
            <select
              className="form-control"
              id="discountSlab"
              value={formData.discountSlab}
              onChange={handleChange}
            >
              <option value="" className="text-primary">
                Select discount slab
              </option>
              {/* Add options as needed */}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="allowDiscount" className="mb-2">
              Allow Discount<span> *</span>
            </label>
            <select
              className="form-control"
              id="allowDiscount"
              value={formData.allowDiscount}
              onChange={handleChange}
            >
              <option value="" className="text-primary">
                Select allow discount
              </option>
              {/* Add options as needed */}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="groupCode" className="mb-2">
              Group code<span> *</span>
            </label>
            <select
              className="form-control"
              id="groupCode"
              value={formData.groupCode}
              onChange={handleChange}
            >
              <option value="" className="text-primary">
                Select group code
              </option>
              {/* Add options as needed */}
            </select>
          </div>
        </div>
      </div>
    </div>
          
  );
}

export default Oprs;
