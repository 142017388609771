import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../../App.css';
import MainForm from './MainForm';
import Navbar from '../Common/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import BottomNav from "../Common/BottomNav";

function Global() {
  const location = useLocation();
  const { request } = location.state || {};
  console.log(request);

  // Call hooks unconditionally
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    fullName: '',
    brandName: '',
    strength: '',
    qtyPerPack: '',
    mrpPerPack: '',
    puRatePerPack: '',
    shelfLifeDays: '',
    marketedBy: '',
    category: '',
    head: '',
    classType: '',
    pack: '',
    gst: '',
    schedule: '',
    storage: '',
    hsnSacNo: '',
    itemCohort: '',
    publish: '',
    sellingType: '',
    returnType: '',
    discount: '',
    mfgDate: '',
    expDate: '',
    note: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validate = () => {
    let newErrors = {};
    for (let key in formData) {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Form Data Submitted:', formData);
    }
  };

  // If no request, return early
  if (!request) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="container-fluid" style={{marginBottom: '90px'}}>
        <div className="row justify-content-center">
          <div className="col-md-11">
            {/* <nav className="mt-3" style={{ "--bs-breadcrumb-divider": "'>>'" }} aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" style={{ marginLeft: '18px', textDecoration: 'none' }}><a href="#"><FontAwesomeIcon icon={faHome} /></a></li>
                <li className="breadcrumb-item" style={{ textDecoration: 'none' }}><a href="/user/request-list">Request List</a></li>
                <li className="breadcrumb-item"><a href="">Global Form</a></li>
              </ol>
            </nav> */}
            <div className="card card1 custom-card shadow p-2 bg-body rounded">
              <h5 className="card-header">GLOBAL FORM</h5>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <MainForm formData={formData} handleChange={handleChange} errors={errors} request={request} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Global;
