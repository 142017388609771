import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
  // Retrieve and parse the session data from localStorage
  const storedSessionData = localStorage.getItem('session');
  const initialSessionData = storedSessionData ? JSON.parse(storedSessionData) : {
    userRole: '',
    category: ''
  };

  // Initialize the state with parsed session data
  const [sessionData, setSessionData] = useState(initialSessionData);

  // Function to update session data
  const updateSessionData = (key, value) => {
    setSessionData((prevData) => {
      const updatedData = { ...prevData, [key]: value };
      localStorage.setItem('session', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const setSessionValues = (data) => {
    setSessionData(data);
    localStorage.setItem('session', JSON.stringify(data));
  };

  useEffect(() => {
    // Sync state with localStorage when component mounts
    const storedSessionData = localStorage.getItem('session');
    if (storedSessionData) {
      setSessionData(JSON.parse(storedSessionData));
    }
  }, []);

  return (
    <SessionContext.Provider value={{ sessionData, updateSessionData, setSessionValues }}>
      {children}
    </SessionContext.Provider>
  );
};

// Custom hook to use the SessionContext
export const useSession = () => {
  return useContext(SessionContext);
};
