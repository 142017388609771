import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';

const AutocompleteTwo = ({ id, url, placeholder, value, onChange }) => {
    const [options, setOptions] = useState([]);
    const selectRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(url);
                const fetchedOptions = response.data.map(item => ({
                    id: item.code.toString(), 
                    text: item.code.toUpperCase() + ' - ' + item.name.toUpperCase()
                }));
                setOptions(fetchedOptions);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [url]);

    const handleSelectChange = (selectedValue) => {
        if (onChange) {
            onChange({ target: { id, value: selectedValue } });
        }
    };

    useEffect(() => {
        if (selectRef.current) {
            $(selectRef.current).select2({
                ajax: {
                    url: url,
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term
                        };
                    },
                    processResults: function (data) {
                        const options = data.map(item => ({
                            id: item.code.toString(),
                            text: item.code.toUpperCase() + ' - ' + item.name.toUpperCase()
                        }));
                        return {
                            results: options
                        };
                    },
                    cache: true
                },
                placeholder: placeholder || 'Search...',
                minimumInputLength: 3,
                allowClear: true,
                width: '100%',
                tags: true,
                createTag: function (params) {
                    const term = $.trim(params.term).toUpperCase();
                    if (term === '') {
                        return null;
                    }
                    return {
                        id: term,
                        text: `${term} (NEW ITEM)`,
                        isNew: true
                    };
                },
                templateSelection: function (selected) {
                    return selected.text.replace(/\s*\(NEW\s*ITEM\)\s*$/, '');
                },
                dropdownCssClass: 'custom-dropdown', // Custom class for dropdown
                containerCssClass: 'custom-container' // Custom class for container
            });

            $(selectRef.current).on('change', function (e) {
                const selectedValue = $(this).val();
                handleSelectChange(selectedValue);
            });
        }

        return () => {
            if (selectRef.current) {
                $(selectRef.current).select2('destroy');
            }
        };
    }, [url, placeholder, id, onChange]);

    useEffect(() => {
        if (selectRef.current && value !== undefined) {
            $(selectRef.current).val(value).trigger('change.select2');
        }
    }, [value]);

    useEffect(() => {
        if (selectRef.current) {
            $(selectRef.current).on('select2:open', function () {
                $('.select2-search__field').css('border-bottom', '2px solid black !important');
            });
        }
    }, []);

    return (
        <select ref={selectRef} id={id} className="form-control">
            <option value=""></option>
        </select>
    );
};

export default AutocompleteTwo;
