// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.total-card {
  background-image: linear-gradient(230deg, #759bff, #843cf6);
  border: 0;
  cursor: pointer;
  border-radius: 0.625rem;
}

.pending-card {
  background-image: linear-gradient(230deg, #ffc480, #ff763b);
  border: 0;
  border-radius: 0.625rem;
  cursor: pointer;
}

.approved-card {
  background: #11998e;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #38ef7d, #11998e); */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border: 0;
  border-radius: 0.625rem;
  cursor: pointer;
}

.rejected-card {
  background-image: linear-gradient(230deg, #fc5286, #fbaaa2);
  border: 0;
  cursor: pointer;
  border-radius: 0.625rem;
}

.dash-icon {
  font-size: 3em;
  opacity: 0.8;
}

.dash-title {
  font-weight: 500;
  /* line-height: 18px; */
}`, "",{"version":3,"sources":["webpack://./src/styles/AdminCards.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;EAC3D,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,2DAA2D;EAC3D,SAAS;EACT,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,qEAAqE;EACrE,+BAA+B;EAC/B,uDAAuD;EACvD,qEAAqE;;EAErE,SAAS;EACT,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,2DAA2D;EAC3D,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".total-card {\r\n  background-image: linear-gradient(230deg, #759bff, #843cf6);\r\n  border: 0;\r\n  cursor: pointer;\r\n  border-radius: 0.625rem;\r\n}\r\n\r\n.pending-card {\r\n  background-image: linear-gradient(230deg, #ffc480, #ff763b);\r\n  border: 0;\r\n  border-radius: 0.625rem;\r\n  cursor: pointer;\r\n}\r\n\r\n.approved-card {\r\n  background: #11998e;\r\n  /* fallback for old browsers */\r\n  /* background: -webkit-linear-gradient(to right, #38ef7d, #11998e); */\r\n  /* Chrome 10-25, Safari 5.1-6 */\r\n  background: linear-gradient(to right, #38ef7d, #11998e);\r\n  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\r\n\r\n  border: 0;\r\n  border-radius: 0.625rem;\r\n  cursor: pointer;\r\n}\r\n\r\n.rejected-card {\r\n  background-image: linear-gradient(230deg, #fc5286, #fbaaa2);\r\n  border: 0;\r\n  cursor: pointer;\r\n  border-radius: 0.625rem;\r\n}\r\n\r\n.dash-icon {\r\n  font-size: 3em;\r\n  opacity: 0.8;\r\n}\r\n\r\n.dash-title {\r\n  font-weight: 500;\r\n  /* line-height: 18px; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
