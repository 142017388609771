import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import axios from 'axios';
import swal from 'sweetalert';
// import BottomNav from "../Common/BottomNav";
// import { useDropzone } from 'react-dropzone';
import DropzoneField from "../Common/Dropzone";
import AutocompleteTwo from '../Common/AutoComplete2';
// iport AutoComplete from '../Common/AutoComplete';
import BottomNav from "../Common/BottomNav";
import { useNavigate } from 'react-router-dom';


function StoreForm() {
    const [selectedOption, setSelectedOption] = useState("");
    const serverUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1); // Track the current step
    const [formData, setFormData] = useState({
        branchCode: "",
        Mobileno: "",
        Email: "",
        category: '',
        requestid: "",
        productname: "",
        ContactName: "",
        suppliername: "",
        Contactno: "",
        invoiceImage: null,
        frontImage: null,
        backImage: null,
        leftImage: null,
        rightImage: null,
    });

    const generateRandomRequestId = () => {
        const branchCode = localStorage.getItem('branchCode');

        const randomDigits = Math.floor(1000 + Math.random() * 9000);

        return `${branchCode}${randomDigits}`;
    };


    useEffect(() => {
        const storedBranchCode = localStorage.getItem('branchCode');
        const storedEmail = localStorage.getItem('email');
        const storedBranchName = localStorage.getItem('branchName');

        setFormData(prevFormData => ({
            ...prevFormData,
            branchCode: storedBranchCode || "",
            Email: storedEmail || "",
            requestid: generateRandomRequestId(),
            branchName: storedBranchName
        }));
    }, []);

    const handleChange = (e) => {
        console.log(e);
        if (!e.target) {
            console.error("Event target is undefined:", e);
            return;
        }

        const { id, value } = e.target;
        console.log("ID:", id);
        setFormData({ ...formData, [id]: value });
    };

    const handleDrop = (acceptedFiles, field) => {
        setFormData({ ...formData, [field]: acceptedFiles[0] });
    };

    const handleFileChange = (e) => {
        const { id, files } = e.target;
        setFormData({ ...formData, [id]: files[0] });
    };

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        setFormData({ ...formData, category: e.target.value });
    };

    const validateStep = () => {
        const validationErrors = {};

        switch (step) {
            case 1:
                if (!formData.requestid) {
                    validationErrors.requestid = "Request id is required";
                }
                if (!formData.branchCode) {
                    validationErrors.branchCode = "Branch code is required";
                }
                // if (!formData.Email) {
                //     validationErrors.Email = "Email is required";
                // } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
                //     validationErrors.Email = "Invalid email address";
                // }
                if (!formData.category) {
                    validationErrors.category = "Category type is required";
                }
                if (!formData.productname) {
                    validationErrors.productname = "Product name is required";
                }
                if (!formData.suppliername) {
                    validationErrors.suppliername = "Supplier name is required";
                }
                if (!formData.Mobileno) {
                    validationErrors.Mobileno = "Supplier Mobile No is required";
                } else if (!/^\d{10}$/i.test(formData.Mobileno)) {
                    validationErrors.Mobileno = "Mobile no should be 10 digits";
                }
                if (!formData.ContactName) {
                    validationErrors.ContactName = "Your Name is required";
                }
                if (!formData.Contactno) {
                    validationErrors.Contactno = "Contact No is required";
                } else if (!/^\d{10}$/i.test(formData.Contactno)) {
                    validationErrors.Contactno = "Contact No should be 10 digits";
                }
                break;
            case 2:
                if (!formData.invoiceImage) {
                    validationErrors.invoiceImage = "Invoice image is required";
                }
                if (!formData.frontImage) {
                    validationErrors.frontImage = "Front image is required";
                }
                if (!formData.backImage) {
                    validationErrors.backImage = "Back image is required";
                }
                break;
            default:
                break;
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleNext = (e) => {
        e.preventDefault();
        const isValid = validateStep();

        if (isValid) {
            setStep(step + 1);
        }
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateStep();

        if (isValid) {
            setLoading(true);
            try {
                const submissionData = new FormData();
                for (const key in formData) {
                    if (formData[key] !== null) {
                        submissionData.append(key, formData[key]);
                    }
                }

                const formDataObject = Object.fromEntries(submissionData.entries());
                console.log('FormData as Object:', formDataObject);

                const apiUrl = `${serverUrl}/api/user/store-request`;

                const response = await axios.post(apiUrl, formDataObject, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                swal({
                    title: "Success!",
                    text: response.data.request.req_id + " created successfully!",
                    icon: "success",
                    button: "OK",
                });
                navigate('/user/request-list');
                console.log("Form Data Submitted:", response.data);
            } catch (error) {
                console.error("Error submitting form data:", error);

                // Determine the error message
                let errorMessage = "An error occurred while submitting the form.";
                if (error.response) {
                    // Server responded with a status other than 200 range
                    errorMessage = error.response.data.message || "Server error. Please try again later.";
                } else if (error.request) {
                    // Request was made but no response received
                    errorMessage = "No response from server. Please check your network connection.";
                } else {
                    // Something else caused the error
                    errorMessage = error.message;
                }

                // Show error alert
                swal({
                    title: "Error!",
                    text: errorMessage,
                    icon: "error",
                    button: "OK",
                });
            } finally {
                setLoading(false);
            }
        }
    };

    // const DropzoneField = ({ field }) => {
    //     const { getRootProps, getInputProps } = useDropzone({
    //         onDrop: (acceptedFiles) => handleDrop(acceptedFiles, field)
    //     });

    //     return (
    //         <div {...getRootProps({ className: 'dropzone' })}>
    //             <input {...getInputProps()} />
    //             <p>Drag & drop a file here, or click to select a file</p>
    //         </div>
    //     );
    // };


    return (
        <div className="container">
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            <div className="row justify-content-center mt-2 align-items-center">

                <div className="col-md-9" style={{ marginBottom: '50px' }}>
                    {/* Stepper */}
                    {/* <div className="progress mb-2">
                        <div className={`progress-bar`} role="progressbar" style={{ width: `${(step / 2) * 100}%` }} aria-valuenow={(step / 2) * 100} aria-valuemin="0" aria-valuemax="100">
                           
                            {step === 1 ? "Item Details" : "Media Upload"}
                        </div>
                    </div> */}
                    {/* End Stepper */}
                    <div className="card card1 custom-card bg-body rounded">
                        <div className="card-header fs-4" style={{ backgroundColor: '#004400', color: 'white' }}>
                            {/* {step === 1 ? "Product and Supplier Information" : "Media Upload"} */}
                            Add New Item
                        </div>
                        <div className="card-body">

                            <form onSubmit={handleSubmit}>
                                {/* Form content for step 1 */}
                                {step === 1 && (
                                    <>
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                {/* <div className="form-group">
                                                    <label htmlFor="category" className="mb-2 custom-font-size">Main Category <span>*</span></label>
                                                    <select className="form-control capital" id="category" value={formData.category} onChange={handleChange}>
                                                        <option value="">Select Category</option>
                                                        <option value="1">SURGICAL</option>
                                                        <option value="2">MEDICINE</option>
                                                        <option value="3">FMCG FOOD</option>
                                                        <option value="4">FMCG NON FOOD</option>
                                                        <option value="5">OPRS</option>
                                                    </select>
                                                    {errors.category && (
                                                        <span className="error-message text-danger">
                                                            {errors.category}
                                                        </span>
                                                    )}
                                                </div> */}
                                                <div class="form-floating">
                                                    <select class="form-select custom-form-control capital" id="category" aria-label="" value={formData.category} onChange={handleChange}>
                                                        <option value="">Select Category</option>
                                                        <option value="1">SURGICAL</option>
                                                        <option value="2">MEDICINE</option>
                                                        <option value="3">FMCG FOOD</option>
                                                        <option value="4">FMCG NON FOOD</option>
                                                        <option value="5">OPRS</option>
                                                    </select>
                                                    <label for="category">Main Category <span>*</span> </label>
                                                    {errors.category && (
                                                        <span className="error-message text-danger">
                                                            {errors.category}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="productname" className="form-label custom-font-size">
                                                        Product Name<span> *</span>
                                                    </label>
                                                    {/* <input
                                                        type="text"
                                                        className="form-control  capital"
                                                        id="productname"
                                                        placeholder="Enter Your Product Name"
                                                        value={formData.productname}
                                                        onChange={handleChange}
                                                    /> */}
                                                    {/* <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-control capital"
                                                            id="productname"
                                                            placeholder="Enter Product Name"
                                                            value={formData.productname}
                                                            onChange={handleChange} />
                                                        <label for="floatingInput">Product Name<span> *</span></label>
                                                    </div> */}
                                                    {/* <AutocompleteTwo
                                                        url="${serverUrl}/api/autocomplete/productname"
                                                        value={formData.productname}
                                                        onChange={handleChange}
                                                        label="Product Name"
                                                        id="productname"
                                                        className='custom-font-size'
                                                    /> */}
                                                    <AutocompleteTwo
                                                        url={`${serverUrl}/api/autocomplete/productname`}
                                                        placeholder="SELECT PRODUCT NAME"
                                                        value={formData.productname}
                                                        id="productname"
                                                        className="capital"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.productname && (
                                                        <span className="error-message text-danger">
                                                            {errors.productname}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>


                                        </div>

                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* <label htmlFor="suppliername" className="form-label custom-font-size">
                                                        Supplier Name <span> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control  capital"
                                                        id="suppliername"
                                                        placeholder="Enter Your Supplier Name"
                                                        value={formData.suppliername}
                                                        onChange={handleChange}
                                                    /> */}
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-control capital"
                                                            id="suppliername"
                                                            placeholder="Enter Supplier Name"
                                                            value={formData.suppliername}
                                                            autoComplete="off"
                                                            onChange={handleChange} />
                                                        <label for="floatingInput">Supplier Name<span> *</span></label>
                                                    </div>
                                                    {errors.suppliername && (
                                                        <span className="error-message text-danger">
                                                            {errors.suppliername}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* <label htmlFor="Mobileno" className="form-label custom-font-size">
                                                        Supplier Mobile No <span> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control  capital"
                                                        id="Mobileno"
                                                        placeholder="Enter Your Supplier Mobile No"
                                                        value={formData.Mobileno}
                                                        onChange={handleChange}
                                                    /> */}
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-control capital"
                                                            id="Mobileno"
                                                            placeholder="Enter Supplier Mobile No"
                                                            value={formData.Mobileno}
                                                            autoComplete="off"
                                                            onChange={handleChange} />
                                                        <label for="floatingInput">Supplier Mobile No<span> *</span></label>
                                                    </div>
                                                    {errors.Mobileno && (
                                                        <span className="error-message text-danger">
                                                            {errors.Mobileno}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* <label htmlFor="ContactName" className="form-label custom-font-size">
                                                        Store Contact Person Name <span> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control  capital"
                                                        id="ContactName"
                                                        placeholder="Enter Your Name"
                                                        value={formData.ContactName}
                                                        onChange={handleChange}
                                                    /> */}
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-control capital"
                                                            id="ContactName"
                                                            placeholder="Enter your name"
                                                            value={formData.ContactName}
                                                            autoComplete="off"
                                                            onChange={handleChange} />
                                                        <label for="floatingInput">Store Contact Person Name<span> *</span></label>
                                                    </div>
                                                    {errors.ContactName && (
                                                        <span className="error-message text-danger">
                                                            {errors.ContactName}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    {/* <label htmlFor="Contactno" className="form-label custom-font-size">
                                                        Store Contact Person No <span> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control  capital"
                                                        id="Contactno"
                                                        placeholder="Enter Your Contact No"
                                                        value={formData.Contactno}
                                                        onChange={handleChange}
                                                    /> */}
                                                    <div className="form-floating mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form-control capital"
                                                            id="Contactno"
                                                            placeholder="Enter Contact No"
                                                            value={formData.Contactno}
                                                            autoComplete="off"
                                                            onChange={handleChange} />
                                                        <label for="floatingInput">Store Contact Person No<span> *</span></label>
                                                    </div>
                                                    {errors.Contactno && (
                                                        <span className="error-message text-danger">
                                                            {errors.Contactno}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <hr style={{ backgroundColor: "black" }} /> */}
                                    </>
                                )}

                                {/* Form content for step 2 */}
                                {step === 2 && (
                                    <>
                                        {/* <div className="row mt-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="invoiceImage" className="mb-2 custom-font-size">Invoice Image <span>*</span></label>
                                                    <DropzoneField field="invoiceImage" />
                                                    {errors.invoiceImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.invoiceImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="frontImage" className="mb-2 custom-font-size">Front Image <span>*</span></label>
                                                    <DropzoneField field="frontImage" />
                                                    {errors.frontImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.frontImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="backImage" className="mb-2 custom-font-size">Back Image <span>*</span></label>
                                                    <DropzoneField field="backImage" />
                                                    {errors.backImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.backImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="leftImage" className="mb-2 custom-font-size">Left Image <span>*</span></label>
                                                    <DropzoneField field="leftImage" />
                                                    {errors.leftImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.leftImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="rightImage" className="mb-2 custom-font-size">Right Image <span>*</span></label>
                                                    <DropzoneField field="rightImage" />
                                                    {errors.rightImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.rightImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="invoiceImage" className="mb-2 custom-font-size">Invoice Image <span>*</span></label>
                                                    <DropzoneField field="invoiceImage" formData={formData} setFormData={setFormData} />
                                                    {errors.invoiceImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.invoiceImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="frontImage" className="mb-2 custom-font-size">Front Image <span>*</span></label>
                                                    <DropzoneField field="frontImage" formData={formData} setFormData={setFormData} />
                                                    {errors.frontImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.frontImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="backImage" className="mb-2 custom-font-size">Back Image <span>*</span></label>
                                                    <DropzoneField field="backImage" formData={formData} setFormData={setFormData} />
                                                    {errors.backImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.backImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Other image fields similar to above */}
                                        <div className="row mt-2">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="leftImage" className="mb-2 custom-font-size">Left Image</label>
                                                    <DropzoneField field="leftImage" formData={formData} setFormData={setFormData} />
                                                    {errors.leftImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.leftImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="rightImage" className="mb-2 custom-font-size">Right Image</label>
                                                    <DropzoneField field="rightImage" formData={formData} setFormData={setFormData} />
                                                    {errors.rightImage && (
                                                        <span className="error-message text-danger">
                                                            {errors.rightImage}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mt-2">
                                          
                                        </div> */}
                                        {/* <hr /> */}
                                    </>
                                )}

                                {/* Navigation buttons */}
                                <div className="row justify-content-between mt-4">
                                    {step < 2 && (
                                        <div className="col-12 text-end">
                                            <button type="submit" className="btn btn-primary" onClick={handleNext} style={{ backgroundColor: '#004400', border: '0' }}>Next</button>
                                        </div>
                                    )}
                                    {step === 2 && (
                                        <div className="col-12">
                                            <div className="row justify-content-between">
                                                <div className="col-6 col-md-2 text-start">
                                                    <button type="button" className="btn btn-primary" onClick={handlePrev} style={{ backgroundColor: '#004400', border: '0' }}>Previous</button>
                                                </div>
                                                <div className="col-6 col-md-2 text-end">
                                                    <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#004400', border: '0' }}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <BottomNav /> */}
        </div>
    );
}

export default StoreForm;
