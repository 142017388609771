import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

const dropzoneStyle = {
    // border: '2px dashed #007bff',
    border: '2px dashed grey',
    padding: '10px',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#dbdbdb'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    justifyContent: 'center'
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const removeButton = {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const DropzoneField = ({ field, formData, setFormData }) => {
    const [file, setFile] = useState(null);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple: false,
        onDrop: acceptedFiles => {
            const uploadedFile = acceptedFiles[0];
            setFile(Object.assign(uploadedFile, {
                preview: URL.createObjectURL(uploadedFile)
            }));
            setFormData({ ...formData, [field]: uploadedFile });
        }
    });

    const removeFile = () => {
        if (file) {
            URL.revokeObjectURL(file.preview);
            setFile(null);
            setFormData({ ...formData, [field]: null });
        }
    };

    useEffect(() => {
        return () => {
            if (file) URL.revokeObjectURL(file.preview);
        };
    }, [file]);

    return (
        <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
            <input {...getInputProps()} />
            {/* <p>Drag & drop, or Select a file</p> */}
            <FontAwesomeIcon icon={faFileUpload} />
            {file && (
                <aside style={thumbsContainer}>
                    <div style={thumb}>
                        <div style={thumbInner}>
                            <img
                                src={file.preview}
                                style={img}
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                        </div>
                        <button type="button" style={removeButton} onClick={removeFile}>x</button>
                    </div>
                </aside>
            )}
        </div>
    );
};

export default DropzoneField;
