// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .main-layout1 {
    display: flex;
    height: 100vh;
  }
  
  .main-content1 {
    flex: 1 1;
    padding: 20px;
    
    /* background-color: #fff; */
  } 
  `, "",{"version":3,"sources":["webpack://./src/styles/MainLayout.css"],"names":[],"mappings":"CAAC;IACG,aAAa;IACb,aAAa;EACf;;EAEA;IACE,SAAO;IACP,aAAa;;IAEb,4BAA4B;EAC9B","sourcesContent":[" .main-layout1 {\r\n    display: flex;\r\n    height: 100vh;\r\n  }\r\n  \r\n  .main-content1 {\r\n    flex: 1;\r\n    padding: 20px;\r\n    \r\n    /* background-color: #fff; */\r\n  } \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
