import React from 'react';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import '../../styles/zoom.css';

const MainForm = ({ formData, handleChange, errors, request }) => {

  const getStatusBadge = (status) => {
    switch (status) {
      case 'Pending':
        return (
          <span className="badge badge-pending">
            <FontAwesomeIcon icon={faHourglassHalf} style={{ marginRight: '5px' }} />
            {status}
          </span>
        );
      case 'Approved':
        return (
          <span className="badge badge-approved">
            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '5px' }} />
            {status}
          </span>
        );
      case 'Rejected':
        return (
          <span className="badge badge-rejected">
            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '5px' }} />
            {status}
          </span>
        );
      default:
        return <span className="badge">{status}</span>;
    }
  };
  return (

    <div className='row mt-4'>
      <div className='row mb-1'>
        <div className='col-md-4'>Status : {getStatusBadge(request.status)}</div>
      </div>
      <div className='row mb-1'>
        <div className='col-md-4'>Reason :</div>
      </div>
      <hr />
      <div className="col-md-3">
        <div className="mb-3">
          <label htmlFor="requestid" className="form-label">
            Request Id <span> *</span>
          </label>
          <input
            type="text"
            className="form-control capital"
            id="requestid"
            placeholder="Enter Your Request Id"
            value={request.req_id}
            onChange={handleChange}
            readOnly
          />
          {errors.requestid && (
            <span className="error-message text-danger ">
              {errors.requestid}
            </span>
          )}
        </div>
      </div>
      <div className="col-md-3">
        <div className="mb-3">
          <label htmlFor="branchCode" className="form-label">
            Branch Code <span> *</span>
          </label>
          <input
            type="text"
            className="form-control capital"
            id="branchCode"
            placeholder="Enter Your Branch Code"
            value={request.branch_code}
            readOnly
            onChange={handleChange}
          />
          {errors.branchCode && (
            <span className="error-message text-danger ">
              {errors.branchCode}
            </span>
          )}
        </div>
      </div>
      <div className="col-md-3">
        <div className="mb-3">
          <label htmlFor="Email" className="form-label">
            Email <span> *</span>
          </label>
          <input
            type="email"
            className="form-control capital"
            id="Email"
            placeholder="Enter Your Email"
            value={request.user_information.store_email}
            onChange={handleChange}
            readOnly
          />
          {errors.Email && (
            <span className="error-message text-danger ">
              {errors.Email}
            </span>
          )}
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Product Name<span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="name"
              placeholder="Enter Your Product Name"
              value={request.product_name}
              onChange={handleChange}
              readOnly
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label">
              Product Full Name <span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="fullName"
              placeholder="Enter Your Full Name"
              value={formData.fullName}
              onChange={handleChange}
              readOnly
            />
            {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="brandName" className="form-label">
              Brand Name<span> *</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="brandName capital"
              placeholder="Enter Your Brandname"
              value={formData.brandName}
              onChange={handleChange}
              readOnly
            />
            {errors.brandName && <div className="text-danger">{errors.brandName}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="marketedBy" className="form-label capital">
              Marketed By<span> *</span>
            </label>
            {/* <select
              className="form-control"
              id="marketedBy"
              value={formData.marketedBy}
              onChange={handleChange}
            >
              <option value="">Select Marketed By</option>
              <option value="Option1">O</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="marketedBy"
              placeholder=""
              value={formData.marketedBy}
              onChange={handleChange}
              readOnly
            />
            {errors.marketedBy && <div className="text-danger">{errors.marketedBy}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="pack" className="form-label">
              Pack:<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="pack"
              value={formData.pack}
              onChange={handleChange}
            >
              <option value="">Select Pack</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="pack"
              placeholder=""
              value={formData.pack}
              onChange={handleChange}
              readOnly
            />
            {errors.pack && <div className="text-danger">{errors.pack}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="qtyPerPack" className="form-label">
              Qty/Pack<span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="qtyPerPack"
              placeholder="Enter Your Qty/Pack"
              value={formData.qtyPerPack}
              onChange={handleChange}
              readOnly
            />
            {errors.qtyPerPack && <div className="text-danger">{errors.qtyPerPack}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="mrpPerPack" className="form-label">
              Mrp Pack<span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="mrpPerPack"
              placeholder="Enter Your MRP Pack"
              value={formData.mrpPerPack}
              onChange={handleChange}
              readOnly
            />
            {errors.mrpPerPack && <div className="text-danger">{errors.mrpPerPack}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="puRatePerPack" className="form-label">
              PU Rate/Pack<span> </span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="puRatePerPack"
              placeholder="Enter Your PU Rate Pack "
              value={formData.puRatePerPack}
              onChange={handleChange}
              readOnly
            />
            {errors.puRatePerPack && <div className="text-danger">{errors.puRatePerPack}</div>}
          </div>
        </div>
      </div>
      <hr />

      {/* inner row end */}
      <div className='row'>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="category" className="mb-2">Main Category <span>*</span></label>
            <select className="form-control capital" id="category" value={request.main_category} onChange={handleChange} readOnly>
              <option value="">Select Category</option>
              <option value="1">SURGICAL</option>
              <option value="2">MEDICINE</option>
              <option value="3">FMCG FOOD</option>
              <option value="4">FMCG NON FOOD</option>
              <option value="5">OPRS</option>
            </select>
            {/* <input
              type="text"
              className="form-control"
              id="category"
              placeholder="Enter Your Brandname"
              value={request.main_category}
              onChange={handleChange}
              readOnly
            /> */}
            {errors.category && (
              <span className="error-message text-danger">
                {errors.category}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Category (L1)<span> *</span>
            </label>
            <select
              className="form-control capital"
              id="category"
              value={formData.category}
              onChange={handleChange}
              readOnly
            >
              <option value="">Select Category</option>
              <option value="Medicine">Medicine</option>
              <option value="Surgical">Surgical</option>
              <option value="Oprs">Oprs</option>
              <option value="Fmcg">Fmcg</option>
            </select>
            {errors.category && <div className="text-danger">{errors.category}</div>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="head" className="form-label">
              Head (L2)<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="head"
              value={formData.head}
              onChange={handleChange}
            >
              <option value="">Select Head</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="head"
              placeholder=""
              value={formData.head}
              onChange={handleChange}
              readOnly
            />
            {errors.head && <div className="text-danger">{errors.head}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="gst" className="form-label">
              GST <span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="gst"
              value={formData.gst}
              onChange={handleChange}
            >
              <option value="">Select GST %</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="gst"
              placeholder=""
              value={formData.gst}
              onChange={handleChange}
              readOnly
            />
            {errors.gst && <div className="text-danger">{errors.gst}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="hsnSacNo" className="form-label">
              HSN/SAC No<span> *</span>:
            </label>
            {/* <select
              className="form-control capital"
              id="hsnSacNo"
              value={formData.hsnSacNo}
              onChange={handleChange}
            >
              <option value="">Select HSN/SAC No</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="hsnSacNo"
              placeholder=""
              value={formData.hsnSacNo}
              onChange={handleChange}
              readOnly
            />
            {errors.hsnSacNo && <div className="text-danger">{errors.hsnSacNo}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="schedule" className="form-label">
              Schedule<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="schedule"
              value={formData.schedule}
              onChange={handleChange}
            >
              <option value="">Select Schedule</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="schedule"
              placeholder=""
              value={formData.schedule}
              onChange={handleChange}
              readOnly
            />
            {errors.schedule && <div className="text-danger">{errors.schedule}</div>}
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="storage" className="form-label">
              Storage /Temperature<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="storage"
              value={formData.storage}
              onChange={handleChange}
            >
              <option value="">Select Storage /Temperature</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="storage"
              placeholder=""
              value={formData.storage}
              onChange={handleChange}
              readOnly
            />
            {errors.storage && <div className="text-danger">{errors.storage}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="strength" className="form-label">
              Strength<span> </span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="strength"
              placeholder="Enter Your Strength"
              value={formData.strength}
              onChange={handleChange}
              readOnly
            />
            {errors.strength && <div className="text-danger">{errors.strength}</div>}
          </div>
        </div>
        <hr style={{ backgroundColor: 'black' }} />

      </div>
      {/* second row end */}
      <div className='row'>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="itemCohort" className="form-label">
              Item Cohort<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="itemCohort"
              value={formData.itemCohort}
              onChange={handleChange}
            >
              <option value="">Select Item Cohort</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="itemCohort"
              placeholder=""
              value={formData.itemCohort}
              onChange={handleChange}
              readOnly
            />
            {errors.itemCohort && <div className="text-danger">{errors.itemCohort}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Dosage" className="form-label">
              Dosage form /Pack Type:<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="Dosage"
              value={formData.publish}
              onChange={handleChange}
            >
              <option value="">Select Dosage Form /pack Type</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="dosage"
              placeholder=""
              value={formData.dosage}
              onChange={handleChange}
              readOnly
            />
            {errors.dosage && <div className="text-danger">{errors.dosage}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="sellingType" className="form-label">
              Selling Type<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="sellingType"
              value={formData.sellingType}
              onChange={handleChange}
            >
              <option value="">Select Selling Type</option>
              <option value="Option1">Saleable</option>
              <option value="Option2">Non Saleable</option>
              <option value="Option3">Non Saleable</option>
              <option value="Option4">Raw materials</option>
              <option value="Option5">Semi Finish</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="sellingType"
              placeholder=""
              value={formData.sellingType}
              onChange={handleChange}
              readOnly
            />
            {errors.sellingType && <div className="text-danger">{errors.sellingType}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="returnType" className="form-label">
              Return Type<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="returnType"
              value={formData.returnType}
              onChange={handleChange}
            >
              <option value="">Select Return Type</option>
              <option value="Option1">Returnable</option>
              <option value="Option2">Non Returnable To Supplier</option>
              <option value="Option3">Non Returnable To Customers</option>
              <option value="Option3">Non Returnable to Both</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="returnType"
              placeholder=""
              value={formData.returnType}
              onChange={handleChange}
              readOnly
            />
            {errors.returnType && <div className="text-danger">{errors.returnType}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="mfgDate" className="form-label">
              MFG Date<span> *</span>
            </label>
            <input
              type="date"
              className="form-control capital"
              id="mfgDate"
              value={formData.mfgDate}
              onChange={handleChange}
              readOnly
            />
            {errors.mfgDate && <div className="text-danger">{errors.mfgDate}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="expDate" className="form-label">
              EXP Date<span> *</span>
            </label>
            <input
              type="date"
              className="form-control capital"
              id="expDate"
              value={formData.expDate}
              onChange={handleChange}
              readOnly
            />
            {errors.expDate && <div className="text-danger">{errors.expDate}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="shelfLifeDays" className="form-label">
              Shl.Life-Days<span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="shelfLifeDays"
              placeholder="Enter Shelf Life in Days"
              value={formData.shelfLifeDays}
              onChange={handleChange}
              readOnly
            />
            {errors.shelfLifeDays && <div className="text-danger">{errors.shelfLifeDays}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="allowdiscount" className="form-label">
              Allow Discount<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="returnType"
              value={formData.returnType}
              onChange={handleChange}
            >
              <option value="">Select allow discount</option>
              <option value="Option1">Yes</option>
              <option value="Option2">No</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="discount"
              placeholder=""
              value={formData.discount}
              onChange={handleChange}
              readOnly
            />
            {errors.discount && <div className="text-danger">{errors.discount}</div>}
          </div>
        </div>

      </div>
      <hr />
      <div className='row'>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="DiscountSlab" className="form-label">
              Discount Slab<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="returnType"
              value={formData.returnType}
              onChange={handleChange}
            >
              <option value="">Select Discount Slab</option>
              <option value="Option1">Option 1</option>
              <option value="Option2">Option 2</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="discountSlab"
              placeholder="Enter Your Brandname"
              value={formData.discountSlab}
              onChange={handleChange}
              readOnly
            />
            {errors.discountSlab && <div className="text-danger">{errors.discountSlab}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="allowdiscount" className="form-label">
              Publish On App/Web<span> *</span>
            </label>
            {/* <select
              className="form-control capital"
              id="returnType"
              value={formData.returnType}
              onChange={handleChange}
            >
              <option value="">Select Publish app/Web</option>
              <option value="Option1">Yes</option>
              <option value="Option2">No</option>
            </select> */}
            <input
              type="text"
              className="form-control"
              id="publish"
              placeholder="Enter Your Brandname"
              value={formData.publish}
              onChange={handleChange}
              readOnly
            />
            {errors.publish && <div className="text-danger">{errors.publish}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="note" className="form-label">
              Note<span> </span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="note"
              placeholder="Enter a Note"
              value={formData.note}
              onChange={handleChange}
              readOnly
            />
            {errors.note && <div className="text-danger">{errors.note}</div>}
          </div>
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Mobileno" className="form-label ">
              Supplier Mobile No <span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="Mobileno"
              placeholder="Enter Your Supplier Mobile No"
              value={request.user_information.s_mobile_no}
              onChange={handleChange}
              readOnly
            />
            {errors.Mobileno && (
              <span className="error-message text-danger">
                {errors.Mobileno}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="suppliername" className="form-label">
              Supplier Name <span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="suppliername"
              placeholder="Enter Your Supplier Name"
              value={request.user_information.s_name}
              onChange={handleChange}
              readOnly
            />
            {errors.suppliername && (
              <span className="error-message text-danger">
                {errors.suppliername}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="ContactName" className="form-label">
              Store Contact Person Name <span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="ContactName"
              placeholder="Enter Your Name"
              value={request.user_information.store_contact_name}
              onChange={handleChange}
              readOnly
            />
            {errors.ContactName && (
              <span className="error-message text-danger">
                {errors.ContactName}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="Contactno" className="form-label">
              Store Contact No <span> *</span>
            </label>
            <input
              type="text"
              className="form-control capital"
              id="Contactno"
              placeholder="Enter Your Contact No"
              value={request.user_information.store_contact_no}
              onChange={handleChange}
              readOnly
            />
            {errors.Contactno && (
              <span className="error-message text-danger">
                {errors.Contactno}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="invoiceImage" className="form-label">
              Product Invoice / Bill Image <span> *</span>
            </label>
            <div>
              <InnerImageZoom
                src={request.media.invoiceImage}
                zoomSrc={request.media.invoiceImage}
                alt="Product Invoice"
                width="100%"
              />
            </div>
            {errors.invoiceImage && (
              <span className="error-message text-danger">
                {errors.invoiceImage}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="frontImage" className="form-label">
              Product Front Image <span> *</span>
            </label>
            <div>
              <InnerImageZoom
                src={request.media.frontImage}
                zoomSrc={request.media.frontImage}
                alt="Product Front"
                width="100%"
              />
            </div>
            {errors.frontImage && (
              <span className="error-message text-danger">
                {errors.frontImage}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="backImage" className="form-label">
              Product Back Image <span> *</span>
            </label>
            <div>
              <InnerImageZoom
                src={request.media.backImage}
                zoomSrc={request.media.backImage}
                alt="Product Back"
                width="100%"
              />
            </div>
            {errors.backImage && (
              <span className="error-message text-danger">
                {errors.backImage}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="leftImage" className="form-label">
              Product Left Image
            </label>
            <div>
              <InnerImageZoom
                src={request.media.leftImage}
                zoomSrc={request.media.leftImage}
                alt="Product Left"
                width="100%"
              />
            </div>
            {errors.leftImage && (
              <span className="error-message text-danger">
                {errors.leftImage}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="rightImage" className="form-label">
              Product Right Side Image
            </label>
            <div>
              <InnerImageZoom
                src={request.media.rightImage}
                zoomSrc={request.media.rightImage}
                alt="Product Right"
                width="100%"
              />
            </div>
            {errors.rightImage && (
              <span className="error-message text-danger">
                {errors.rightImage}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>

    // main row end





  );
};


export default MainForm;





