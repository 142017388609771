import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../../styles/LoginStyles.css';
import { useSession } from './SessionContext';
import { jwtDecode } from 'jwt-decode';
axios.defaults.withCredentials = true;

const LoginPage = ({ setuserRole }) => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const { setSessionValues } = useSession();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${serverUrl}/api/auth/login`, formData, { withCredentials: true });
      const { token, branchCode, email, userName, roleName, category } = response.data;
      // console.log(response);

      localStorage.setItem('token', token);
      localStorage.setItem('branchCode', branchCode);
      localStorage.setItem('email', email);
      localStorage.setItem('userName', userName);


      setSessionValues({
        userRole: roleName,
        category: category,
      });

      // Redirect based on the role
      if (response.data.roleName === 'admin') {
        setuserRole('admin');
        navigate('/admin/list');
      } else if (response.data.roleName === 'superadmin') {
        setuserRole('superadmin');
        navigate('/superadmin/list');
      } else if (response.data.roleName === 'spoc') {
        setuserRole('spoc');
        navigate('/spoc/list');
      } else if (response.data.roleName === 'store') {
        setuserRole('user');
        navigate('/user');
      }
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000 - Date.now();

      setTimeout(() => {
        logout();
      }, expirationTime);

    } catch (error) {
      setError("Invalid credentials. Please try again.");
    }
  };

  const logout = () => {
    console.log('LOGGED OUT');
    localStorage.removeItem('token');
    localStorage.removeItem('branchCode');
    localStorage.removeItem('email');
    localStorage.removeItem('userName');
    setSessionValues({ userRole: null, category: null });
    navigate('/login');
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000 - Date.now();

        if (expirationTime > 0) {
          setTimeout(() => {
            logout();
          }, expirationTime);
        } else {
          logout();
        }
      }
    };

    checkToken();
  }, []);



  return (
    <div className="authentication-wrapper authentication-cover">
      <div className="authentication-inner row m-0">
        <div className="d-none d-lg-flex col-lg-5 col-xl-5 align-items-center p-5 left">
          <div className="d-flex justify-content-center">
            <img
              src="/images/login4.png"
              className="img-fluid"
              alt="Login"
            />
          </div>
        </div>
        <div className="d-flex col-12 col-lg-7 col-xl-7 align-items-center authentication-bg p-sm-5 p-4">
          <div className="w-px-400 mx-auto">
            <div className="app-brand mb-5">
              <a href="/" className="app-brand-link gap-2">
                <span className="app-brand-logo demo"></span>
              </a>
            </div>
            <h4 className="mb-2">Welcome! 👋</h4>
            <p className="mb-4">Please sign-in to your account</p>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  <FontAwesomeIcon icon={faUser} /> User Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  <FontAwesomeIcon icon={faLock} /> Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  placeholder="Your Password"
                />
              </div>
              <div className="d-grid mx-auto">
                <button type="submit" className="btn btn-login bg-dark text-white">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
